import { gql } from "@apollo/client";

export const ORGANIZATIONAL_CHART = gql`
    query GetOrganizationalChart {
        orgChartOrganisations {
            designation
            id
            level
            parentId
        }
        orgChartPositions {
            designation
            id
            organisationId
            managementRole
        }
        orgChartEmployees {
            id
            academicTitle
            firstName
            lastName
            email
            mobile
            phone
            location
            personnelGroup
            personnelId
            personnelNumber
            positions {
                positionId
            }
        }
    }
`;
