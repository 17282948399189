import React from "react";
import styled from "styled-components";
import { SearchBar } from "../../atom/search-bar/SearchBar";
import { Content } from "../../../css/content";
import { Suggestions } from "../../organism/suggestions/Suggestions";
import { ArrowRight } from "../../../icon/ArrowRight";

const SearchHead = styled.div`
    display: flex;
    align-items: center;
`;

const SearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4rem 0 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    background-color: ${p => p.theme.gray50};
    overflow: hidden;
`;

const SearchBarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`;

const IconArrow = styled(ArrowRight)`
    width: 2rem;
    height: 1.5rem;
    transform: scaleX(-1);
    margin-right: 2rem;
`;

const ButtonBack = styled.button``;

const SearchList = styled(Content)`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`;

export interface MobileSearchProps {
    suggestions: Search.Suggestion[] | null;
    value: string;
    initialTab?: Search.Tab;
    onSelect: (suggestion: Search.Suggestion) => void;
    onInput: (value: string) => void;
    onClose: () => void;
}

export const MobileSearch: React.FC<MobileSearchProps> = ({
    value,
    suggestions,
    initialTab,
    onInput,
    onSelect,
    onClose,
}) => {
    return (
        <SearchWrapper>
            <Content>
                <SearchHead>
                    <ButtonBack type="button" onClick={onClose}>
                        <IconArrow />
                    </ButtonBack>
                    <SearchBarWrapper>
                        <SearchBar value={value} onInput={onInput} />
                    </SearchBarWrapper>
                </SearchHead>
            </Content>
            <SearchList>
                {suggestions && (
                    <Suggestions
                        searchStr={value}
                        suggestions={suggestions}
                        initialTab={initialTab}
                        onClick={onSelect}
                    />
                )}
            </SearchList>
        </SearchWrapper>
    );
};
