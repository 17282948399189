import { SVGProps } from "react";

export const Logo = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 335 150" {...props}>
        <g fill="#EA5B0C">
            <path d="M-263.24 89.74c0-5.52 1-10.74 3-15.67 2-4.92 4.79-9.23 8.36-12.91 3.57-3.68 7.88-6.6 12.91-8.77 5.03-2.16 10.58-3.25 16.64-3.25 6.06 0 11.61 1.08 16.64 3.25 5.03 2.17 9.34 5.09 12.91 8.77 3.57 3.68 6.36 7.98 8.36 12.91 2 4.93 3 10.15 3 15.67s-1 10.74-3 15.67-4.79 9.25-8.36 12.99c-3.57 3.73-7.88 6.69-12.91 8.85-5.03 2.16-10.58 3.25-16.64 3.25-6.06 0-11.61-1.08-16.64-3.25-5.03-2.16-9.34-5.12-12.91-8.85-3.57-3.74-6.36-8.06-8.36-12.99-2-4.93-3-10.15-3-15.67zm40.92 24.19c3.68 0 6.87-.73 9.58-2.19 2.7-1.46 4.95-3.38 6.74-5.76s3.11-4.98 3.98-7.79c.87-2.81 1.3-5.63 1.3-8.44 0-2.7-.43-5.49-1.3-8.36-.87-2.87-2.19-5.46-3.98-7.79s-4.03-4.22-6.74-5.68-5.9-2.19-9.58-2.19c-3.68 0-6.88.73-9.58 2.19-2.71 1.46-4.95 3.36-6.74 5.68-1.79 2.33-3.11 4.93-3.98 7.79-.87 2.87-1.3 5.66-1.3 8.36 0 2.82.43 5.63 1.3 8.44.87 2.82 2.19 5.41 3.98 7.79s4.03 4.3 6.74 5.76 5.9 2.19 9.58 2.19zM-150.71 89.42l-27.6-37.67h24.19l15.26 22.08 15.43-22.08h21.6l-26.79 35.72-11.85 15.59-17.54 24.84h-21.6l28.9-38.48z" />
        </g>
        <defs>
            <path id="a" d="M-337.5 19.5h77.48v107.63h-77.48z" />
        </defs>
        <clipPath id="b">
            <use href="#a" overflow="visible" />
        </clipPath>
        <path
            clipPath="url(#b)"
            fill="#EA5B0C"
            d="M-337.5 106.2h21.67v20.94h-21.67zM-337.5 19.51v20.94h74.03l3.41-20.7v-.24zM-337.5 63.09v20.7h66.48l3.41-20.7z"
        />
        <path
            fill="#EA5B0C"
            d="M-157.57 79.47l29.34 40.78s8.6 13.72 27.54 8.6l13.85-23-14.48 5.53s-8.44 3.36-13.5-3.68c-5.06-7.04-27.97-38.87-27.97-38.87l-14.78 10.64z"
        />
        <linearGradient
            id="c"
            gradientUnits="userSpaceOnUse"
            x1="-92.759"
            y1="56.493"
            x2="-32.823"
            y2="56.493"
            gradientTransform="rotate(4.211 -348.502 -45.17)">
            <stop offset="0" stopColor="#ea5c10" />
            <stop offset="1" stopColor="#f8ad29" />
        </linearGradient>
        <path
            fill="url(#c)"
            d="M-103.58 129.99c15.19-1.25 27.67-7.73 33.17-28.38 1.61-6.05 3.04-13.58 4.82-19.59 2.96-9.97 4.28-22.84 9.22-31.99 4.25-7.88 10.32-17.49 18.87-20.13-6.99-4.61-14.21-7-22.29-6.78-20.26.56-35.81 18.93-39.63 38.84-2.14 11.15 0 22.64-.32 33.98-.15 5.6 1.46 15.34-3.97 16.71"
        />
        <g>
            <g fill="#EA5B0C">
                <path d="M91.76 89.74c0-5.52 1-10.74 3-15.67 2-4.92 4.79-9.23 8.36-12.91 3.57-3.68 7.88-6.6 12.91-8.77 5.03-2.16 10.58-3.25 16.64-3.25 6.06 0 11.61 1.08 16.64 3.25 5.03 2.17 9.34 5.09 12.91 8.77 3.57 3.68 6.36 7.98 8.36 12.91 2 4.93 3 10.15 3 15.67s-1 10.74-3 15.67-4.79 9.25-8.36 12.99c-3.57 3.73-7.88 6.69-12.91 8.85-5.03 2.16-10.58 3.25-16.64 3.25-6.06 0-11.61-1.08-16.64-3.25-5.03-2.16-9.34-5.12-12.91-8.85-3.57-3.74-6.36-8.06-8.36-12.99-2-4.93-3-10.15-3-15.67zm40.92 24.19c3.68 0 6.87-.73 9.58-2.19 2.7-1.46 4.95-3.38 6.74-5.76s3.11-4.98 3.98-7.79c.87-2.81 1.3-5.63 1.3-8.44 0-2.7-.43-5.49-1.3-8.36-.87-2.87-2.19-5.46-3.98-7.79s-4.03-4.22-6.74-5.68-5.9-2.19-9.58-2.19c-3.68 0-6.88.73-9.58 2.19-2.71 1.46-4.95 3.36-6.74 5.68-1.79 2.33-3.11 4.93-3.98 7.79-.87 2.87-1.3 5.66-1.3 8.36 0 2.82.43 5.63 1.3 8.44.87 2.82 2.19 5.41 3.98 7.79s4.03 4.3 6.74 5.76 5.9 2.19 9.58 2.19zM204.29 89.42l-27.6-37.67h24.19l15.26 22.08 15.43-22.08h21.6l-26.79 35.72-11.85 15.59-17.54 24.84h-21.6l28.9-38.48z" />
            </g>
            <defs>
                <path id="d" d="M17.5 19.5h77.48v107.63H17.5z" />
            </defs>
            <clipPath id="e">
                <use href="#d" overflow="visible" />
            </clipPath>
            <path
                clipPath="url(#e)"
                fill="#EA5B0C"
                d="M17.5 106.2h21.67v20.94H17.5zM17.5 19.51v20.94h74.03l3.41-20.7v-.24zM17.5 63.09v20.7h66.48l3.41-20.7z"
            />
            <path
                fill="#EA5B0C"
                d="M197.43 79.47l29.34 40.78s8.6 13.72 27.54 8.6l13.85-23-14.48 5.53s-8.44 3.36-13.5-3.68c-5.06-7.04-27.97-38.87-27.97-38.87l-14.78 10.64z"
            />
            <path
                fill="#EA5B0C"
                d="M251.42 129.99c15.19-1.25 27.67-7.73 33.17-28.38 1.61-6.05 3.04-13.58 4.82-19.59 2.96-9.97 4.28-22.84 9.22-31.99 4.25-7.88 10.32-17.49 18.87-20.13-6.99-4.61-14.21-7-22.29-6.78-20.26.56-35.81 18.93-39.63 38.84-2.14 11.15 0 22.64-.32 33.98-.15 5.6 1.46 15.34-3.97 16.71"
            />
            <path
                fill="#FFF"
                d="M280.73 40.21c-.65 3.05-.84 6.2-.55 9.31 2.56-2.08 5.13-4.16 7.69-6.24.67 2.57 1.45 5.11 2.36 7.61.43 1.18 1 2.35 1.29 3.56.24 1.01.84 2.63.63 3.67 1.1-5.46 2.25-10.99 4.7-16s6.37-9.52 11.57-11.52c-3.12-2.24-8.92-4.56-13.7-4.78-3.84-.17-8.51 1.27-12.12 2.65-2.01.76-6.89 4.34-7.52 5.1a23.33 23.33 0 00-3.15 5.01c3.19-1.11 6.51-1.84 9.86-2.17-.43 1.25-.78 2.52-1.06 3.8z"
            />
        </g>
    </svg>
);
