import React, { useState } from "react";
import styled from "styled-components";
import Image from "next/image";
import { TriangleDown } from "../../../icon/TriangleDown";

const DropdownWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const DropdownHead = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    height: 3.7rem;
    padding: 0 1rem;
    background-color: ${p => p.theme.gray100};
    border-radius: 0.8rem;
`;

const FlagWrapper = styled.div`
    position: relative;
    width: 2.1rem;
    height: 2.1rem;
    margin-right: 0.8rem;
    border: 0.1rem solid ${p => p.theme.gray200};
    background-color: ${p => p.theme.gray200};
    border-radius: 50%;
    overflow: hidden;
    transform: translateZ(0);
`;

const ChevronIcon = styled(TriangleDown)<{ $active?: boolean }>`
    width: 0.8rem;
    height: 0.5rem;
    margin-left: auto;
    transform: ${p => (p.$active ? "rotate(-180deg)" : "rotate(0)")};
`;

const DropdownOptions = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(calc(100% + 0.5rem)) translateZ(0);
    min-width: 100%;
    background-color: ${p => p.theme.gray50};
    box-shadow: ${p => p.theme.dropdownShadow};
    border-radius: 0.8rem;
    overflow: hidden;
`;

const DropdownOption = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.8rem 1.4rem;
    border-bottom: 0.1rem solid ${p => p.theme.gray100};

    &:last-child {
        border: none;
    }

    @media (hover: hover) {
        &:hover {
            background-color: ${p => p.theme.gray100};
        }
    }
`;

export interface LanguageDropdownProps {
    languages: Record<string, Common.Language>;
    activeLocale: string;
    onLocaleChange: (locale: string) => void;
}

export const LanguageDropdown: React.FC<LanguageDropdownProps> = ({
    languages,
    activeLocale,
    onLocaleChange,
}) => {
    const [active, setActive] = useState<boolean>(false);

    return (
        <DropdownWrapper>
            <DropdownHead type="button" onClick={() => setActive(prevState => !prevState)}>
                <FlagWrapper>
                    <Image
                        src={languages[activeLocale].flag}
                        layout="fill"
                        objectFit="cover"
                        quality="100"
                        priority
                    />
                </FlagWrapper>
                {languages[activeLocale].countryName}
                <ChevronIcon $active={active} />
            </DropdownHead>
            {active && (
                <DropdownOptions>
                    {Object.keys(languages)
                        .filter(locale => locale !== activeLocale)
                        .map(locale => (
                            <DropdownOption
                                key={locale}
                                onClick={() => {
                                    setActive(false);
                                    onLocaleChange(locale);
                                }}>
                                <FlagWrapper>
                                    <Image
                                        src={languages[locale].flag}
                                        alt={locale}
                                        layout="fill"
                                        objectFit="cover"
                                        quality="100"
                                        priority
                                    />
                                </FlagWrapper>
                                {languages[locale].countryName}
                            </DropdownOption>
                        ))}
                </DropdownOptions>
            )}
        </DropdownWrapper>
    );
};
