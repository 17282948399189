import { SVGProps } from "react";

export const Moon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.455 20" fill="currentColor" {...props}>
        <path
            d="M11.309,4.857a11.6,11.6,0,0,0,8.918,14,8.549,8.549,0,0,1-6.135,2.607c-.107,0-.214,0-.321,0a8.534,8.534,0,0,1-2.462-16.6M12.446,3a.771.771,0,0,0-.134.012A10.074,10.074,0,0,0,13.715,23c.126,0,.252,0,.378,0a10.056,10.056,0,0,0,8.232-4.274.776.776,0,0,0-.6-1.2A10.062,10.062,0,0,1,13.145,4.062.781.781,0,0,0,12.446,3Z"
            transform="translate(-3.999 -3)"
        />
    </svg>
);
