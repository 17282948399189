import { SVGProps } from "react";

export const Organisation = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
        <path
            d="M14.286 20H20v-5.714h-2.143v-3.572a1.43 1.43 0 00-1.429-1.429h-5.714V5.714h2.143V0H7.143v5.714h2.143v3.571H3.572a1.43 1.43 0 00-1.429 1.429v3.571H0V20h5.714v-5.714H3.571v-3.572h5.715v3.571H7.143V20h5.714v-5.714h-2.143v-3.572h5.715v3.571h-2.143zM8.571 4.286V1.429h2.858v2.857zM4.285 15.715v2.857H1.428v-2.858zm7.143 0v2.857H8.571v-2.858zm7.143 0v2.857h-2.857v-2.858z"
            fill="currentColor"
        />
    </svg>
);
