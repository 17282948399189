import { SVGProps } from "react";

export const Home = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 17.333" {...props}>
        <path
            d="M10.408.143a.673.673 0 00-.828 0L0 7.613l.828 1.048L2 7.747V16a1.336 1.336 0 001.333 1.333h13.334A1.336 1.336 0 0018 16V7.753l1.172.913L20 7.619zM11.333 16H8.667v-5.333h2.667zm1.333 0v-5.333a1.335 1.335 0 00-1.333-1.333H8.667a1.335 1.335 0 00-1.333 1.333V16h-4V6.708L10 1.514l6.667 5.2V16z"
            fill="currentColor"
        />
    </svg>
);
