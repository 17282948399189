import React, { ReactNode } from "react";
import styled from "styled-components";
import { UserHamburger } from "../../atom/user-hamburger/UserHamburger";
import { SidebarHamburger } from "../../../icon/SidebarHamburger";
import { Feed } from "../../../icon/Feed";
import { FilterArea } from "../../molecule/filter-area/FilterArea";
import { GlobalSearch } from "../global-search/GlobalSearch";
import { useTranslation } from "next-i18next";
import { FilterOrga } from "../../molecule/filter-orga/FilterOrga";

const NavigationWrapper = styled.div`
    position: relative;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    min-height: ${p => p.theme.navigationHeight};
    width: 100%;
    padding: 0 1rem;
    background-color: ${p => p.theme.gray50};

    @media ${p => p.theme.bp.m} {
        border-left: 0.1rem solid ${p => p.theme.gray100};
    }
`;

const NavigationGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const NavigationContent = styled(NavigationGroup)`
    flex: 1 1 0;
`;

const SidebarButton = styled.button<{ active: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    border-radius: 0.4rem;
    background-color: ${p => p.active && p.theme.gray100};

    @media ${p => p.theme.bp.m} {
        width: 3rem;
        height: 3rem;
    }
`;

const MobileSidebarButton = styled(SidebarButton)`
    @media ${p => p.theme.bp.m} {
        display: none;
    }
`;

const DefaultSidebarButton = styled(SidebarButton)`
    display: none;

    @media ${p => p.theme.bp.m} {
        display: flex;
    }

    @media ${p => p.theme.bp.l} {
        display: none;
    }
`;

const SidebarHamburgerIcon = styled(SidebarHamburger)`
    width: 2.4rem;
    height: 1.6rem;

    @media ${p => p.theme.bp.m} {
        width: 1.6rem;
        height: 1.1rem;
    }
`;

const MobileButtonEmergency = styled.div`
    @media ${p => p.theme.bp.m} {
        display: none;
    }
`;

const DefaultButtonEmergency = styled.div`
    display: none;

    @media ${p => p.theme.bp.m} {
        display: block;
    }
`;

const FeedButton = styled(SidebarButton)`
    display: none;
    color: ${p => (p.active ? p.theme.orange700 : p.theme.gray600)};

    @media ${p => p.theme.bp.l} {
        display: flex;
    }
`;

const NewsFilter = styled.div`
    display: none;

    @media ${p => p.theme.bp.l} {
        display: block;
    }
`;

const FeedIcon = styled(Feed)`
    width: 1.9rem;
    height: 1.6rem;
`;

export interface NavigationProps {
    sidebar: boolean;
    mobileSidebar: boolean;
    newsFeed: boolean;
    notificationCenter: boolean;
    toggleSidebar: () => void;
    toggleMobileSidebar: () => void;
    toggleNewsFeed: () => void;
    toggleNotificationCenter: () => void;
    facets: Filters.Facets;
    onSelectFacet: (facetKey: string, value: string) => void;
    onResetFacets: () => void;
    onSearchSelect: (suggestion: Search.Suggestion) => void;
    organization: Organization.Index;
    renderNavigationContent?: ReactNode;
    initialSearchTab?: Search.Tab;
    isNews?: boolean;
    isOrga?: boolean;
    hideGlobalSearch?: boolean;
}

export const Navigation: React.FC<NavigationProps> = ({
    sidebar,
    mobileSidebar,
    newsFeed,
    notificationCenter,
    toggleSidebar,
    toggleMobileSidebar,
    toggleNewsFeed,
    toggleNotificationCenter,
    facets,
    onSelectFacet,
    onResetFacets,
    onSearchSelect,
    organization,
    renderNavigationContent,
    initialSearchTab,
    isNews,
    isOrga,
    hideGlobalSearch,
}) => {
    const { t } = useTranslation();

    return (
        <NavigationWrapper>
            <NavigationContent>
                <DefaultSidebarButton type="button" active={sidebar} onClick={toggleSidebar}>
                    <SidebarHamburgerIcon />
                </DefaultSidebarButton>
                <MobileSidebarButton
                    type="button"
                    active={mobileSidebar}
                    onClick={toggleMobileSidebar}
                >
                    <SidebarHamburgerIcon />
                </MobileSidebarButton>
                {!hideGlobalSearch && (
                    <GlobalSearch
                        organization={organization}
                        onSelect={onSearchSelect}
                        initialTab={initialSearchTab}
                    />
                )}
                {isNews && (
                    <React.Fragment>
                        <NewsFilter>
                            <FilterArea
                                facets={facets}
                                onSelect={onSelectFacet}
                                onReset={onResetFacets}
                            />
                        </NewsFilter>
                        <FeedButton
                            type="button"
                            title={t("toggleFeed")}
                            active={newsFeed}
                            onClick={toggleNewsFeed}
                        >
                            <FeedIcon />
                        </FeedButton>
                    </React.Fragment>
                )}
                {isOrga && <FilterOrga />}
                {renderNavigationContent && (
                    <React.Fragment>{renderNavigationContent}</React.Fragment>
                )}
                {/*<MobileButtonEmergency>*/}
                {/*    <ButtonEmergency />*/}
                {/*</MobileButtonEmergency>*/}
            </NavigationContent>
            <NavigationGroup>
                {/*<DefaultButtonEmergency>*/}
                {/*    <ButtonEmergency />*/}
                {/*</DefaultButtonEmergency>*/}
                <UserHamburger active={notificationCenter} onClick={toggleNotificationCenter} />
            </NavigationGroup>
        </NavigationWrapper>
    );
};
