import { UseFrontendUser } from "../auth/server/frontend";
import { getOrganizationalChart } from "./data/organization";
import { subordinateEmployees, subordinatePositions } from "../organization";
import { getFullName } from "../string";

export const getStaticPageOrganization = async () => {
    const token = await UseFrontendUser();
    const organization = await getOrganizationalChart(token);

    const positions = subordinateEmployees(
        organization.orgChartPositions,
        organization.orgChartEmployees
    );

    const departments = subordinatePositions(organization.orgChartOrganisations, positions);

    return {
        employees: organization.orgChartEmployees,
        departments,
        positions,
    };
};

export const filterOrganizationByStr = (
    str: string,
    organization: Organization.Index
): Organization.Index => {
    const employees = organization.employees.filter(employee => {
        return getFullName(employee).toLowerCase().includes(str.toLowerCase());
    });

    const departments = organization.departments.filter(department => {
        return department.designation.toLowerCase().includes(str.toLowerCase());
    });

    return {
        employees: employees.slice(0, 10),
        departments: departments.slice(0, 10),
        positions: [],
    };
};
