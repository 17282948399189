import React from "react";
import styled from "styled-components";
import { Content } from "../../../css/content";
import { HeadingS } from "../../../css/typography";
import { FilterArea } from "../filter-area/FilterArea";

const NavigationWrapper = styled.div`
    background-color: ${p => p.theme.gray50};
    border-top: 0.1rem solid ${p => p.theme.gray100};
    border-left: 0.1rem solid ${p => p.theme.gray100};
`;

const NavigationInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const NavigationGroup = styled.div`
    display: flex;
`;

export const NavigationItem = styled.button<{ active: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    height: 5rem;
    color: ${p => (p.active ? p.theme.orange700 : p.theme.gray600)};

    &:last-child {
        margin-left: 1.4rem;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: ${p => (p.active ? "block" : "none")};
        width: 100%;
        height: 0.2rem;
        background-color: ${p => p.theme.orange700};
    }
`;

const NavigationContent = styled(Content)`
    @media ${p => p.theme.bp.minMMaxT} {
        width: 100%;
        max-width: none;
        margin: 0;
        padding: 0 1rem;
    }
`;

export interface NewsNavigationProps {
    facets: Filters.Facets;
    onSelectFacet: (facetKey: string, value: string) => void;
    onReset: () => void;
    state: "overview" | "feed";
    setState: (value: "overview" | "feed") => void;
}

export const NewsNavigation: React.FC<NewsNavigationProps> = ({
    facets,
    onSelectFacet,
    onReset,
    state,
    setState,
}) => {
    return (
        <NavigationWrapper>
            <NavigationContent>
                <NavigationInner>
                    <NavigationGroup>
                        <NavigationItem
                            active={"overview" === state}
                            onClick={() => setState("overview")}>
                            <HeadingS>Overview</HeadingS>
                        </NavigationItem>
                        <NavigationItem active={"feed" === state} onClick={() => setState("feed")}>
                            <HeadingS>Feed</HeadingS>
                        </NavigationItem>
                    </NavigationGroup>
                    <FilterArea facets={facets} onSelect={onSelectFacet} onReset={onReset} />
                </NavigationInner>
            </NavigationContent>
        </NavigationWrapper>
    );
};
