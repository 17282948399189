import { SVGProps } from "react";

export const SidebarIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 17" {...props}>
        <path
            d="M18.571 0H1.429A1.423 1.423 0 000 1.417v14.166A1.423 1.423 0 001.429 17h17.142A1.423 1.423 0 0020 15.583V1.417A1.423 1.423 0 0018.571 0zM1.429 1.417h4.285v14.166H1.429zm17.142 14.166H7.143V1.417h11.428z"
            fill="currentColor"
        />
    </svg>
);
