import React from "react";
import styled from "styled-components";
import Link from "next/link";
import { Logo } from "../../../icon/Logo";
import { Home } from "../../../icon/Home";
import { SidebarItem } from "../../atom/sidebar-item/SidebarItem";
import { Organisation } from "../../../icon/Organisation";
import { News } from "../../../icon/News";
import { SidebarIcon } from "../../../icon/SidebarIcon";
import { LogoMark } from "../../../icon/LogoMark";
import { routes } from "../../../lib/router";
import { useTranslation } from "next-i18next";
import { Eye } from "../../../icon/Eye";
import { ImageSearch } from "../../../icon/ImageSearch";
import { Imprint } from "../../../icon/Imprint";
import { HelpCenter } from "../../../icon/HelpCenter";

const SidebarWrapper = styled.div<{ collapsed: boolean }>`
    position: relative;
    z-index: 4;
    height: 100%;
    max-width: 28.1rem;
    padding: 9.6rem 0 1.2rem;
    background-color: ${p => p.theme.gray50};
    overflow: hidden;
    pointer-events: auto;
    transition: max-width 0.3s;
    will-change: max-width;

    @media ${p => p.theme.bp.m} {
        max-width: ${p => (p.collapsed ? "5rem" : "25rem")};
        padding: 4rem 0 1.2rem;
        border-right: 0.1rem solid ${p => p.theme.gray100};
    }
`;

const SidebarInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    @media ${p => p.theme.bp.m} {
        width: 25rem;
    }
`;

const SidebarGroup = styled.div``;

const LogoWrapper = styled.a<{ visible: boolean }>`
    display: flex;
    justify-content: center;
    margin: 0 auto 4rem;
    transition: opacity 0.3s;
    will-change: opacity;

    @media ${p => p.theme.bp.m} {
        opacity: ${p => (p.visible ? 1 : 0)};
        pointer-events: ${p => (p.visible ? "auto" : "none")};
    }
`;

const ButtonMarkLogo = styled.a<{ visible: boolean }>`
    position: absolute;
    top: 6rem;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 0;
    transition-property: opacity;
    transition-duration: ${p => (p.visible ? "0.3s" : "0.15s")};
    transition-delay: ${p => (p.visible ? "0.1s" : 0)};
    will-change: opacity;

    @media ${p => p.theme.bp.m} {
        opacity: ${p => (p.visible ? 1 : 0)};
        pointer-events: ${p => (p.visible ? "auto" : "none")};
    }
`;

const NavigationButtonWrapper = styled.div`
    display: none;

    @media ${p => p.theme.bp.l} {
        display: block;
    }
`;

export interface SidebarProps {
    active: boolean;
    path: string;
    onToggle: () => void;
    openImprint: () => void;
    isPreview?: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({
    active,
    path,
    onToggle,
    openImprint,
    isPreview,
}) => {
    const { t } = useTranslation();

    return (
        <SidebarWrapper collapsed={!active}>
            <SidebarInner>
                <SidebarGroup>
                    <Link href="/" passHref>
                        <LogoWrapper title={t("home")} visible={active}>
                            <Logo width="150" />
                        </LogoWrapper>
                    </Link>
                    <SidebarItem
                        text={t("home")}
                        title={t("home")}
                        action={routes.home}
                        active={path === routes.home}
                    >
                        <Home width="20" />
                    </SidebarItem>
                    <SidebarItem
                        title={t("organization")}
                        text={t("organization")}
                        action={routes.organization}
                        active={path === routes.organization}
                    >
                        <Organisation width="20" />
                    </SidebarItem>
                    <SidebarItem
                        text={t("corporateMedia")}
                        title={t("corporateMedia")}
                        action={routes.media}
                        active={path === routes.media}
                    >
                        <ImageSearch width="22" />
                    </SidebarItem>
                    <SidebarItem
                        text={t("news")}
                        title={t("news")}
                        action={routes.news}
                        active={path === routes.news}
                    >
                        <News width="19" />
                    </SidebarItem>
                    <SidebarItem
                        text={t("serviceCenter")}
                        title={t("serviceCenter")}
                        action={routes.serviceCenterFox}
                        active={path === routes.serviceCenterFox}
                    >
                        <HelpCenter width="20" />
                    </SidebarItem>
                </SidebarGroup>
                <SidebarGroup>
                    {isPreview && (
                        <SidebarItem
                            text="Leave preview"
                            title="Leave preview"
                            action="/api/preview/leave"
                            active={true}
                            locale={false}
                            isSecondary
                        >
                            <Eye width="20" />
                        </SidebarItem>
                    )}
                    <NavigationButtonWrapper>
                        <SidebarItem
                            text={t("navigation")}
                            title={t("toggleSidebar")}
                            action={onToggle}
                            active={!active}
                            isSecondary
                        >
                            <SidebarIcon width="20" />
                        </SidebarItem>
                    </NavigationButtonWrapper>
                    <SidebarItem
                        text={t("imprint")}
                        title={t("openImprint")}
                        action={openImprint}
                        active={path.includes("s=imprint")}
                        isSecondary
                    >
                        <Imprint width={20} />
                    </SidebarItem>
                </SidebarGroup>
            </SidebarInner>
            <Link href="/" passHref>
                <ButtonMarkLogo title={t("home")} visible={!active}>
                    <LogoMark width="20" />
                </ButtonMarkLogo>
            </Link>
        </SidebarWrapper>
    );
};
