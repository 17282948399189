import { SVGProps } from "react";

export const Imprint = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.002 20.002" {...props}>
        <g fill="currentColor">
            <path d="M10.717 14.287V8.572H7.859v1.429h1.429v4.286H7.145v1.429h5.715v-1.429ZM10.002 4.286a1.072 1.072 0 1 0 1.072 1.072 1.072 1.072 0 0 0-1.072-1.072Z" />
            <path d="M10 20a10 10 0 1 1 10-10 10 10 0 0 1-10 10Zm0-18.571A8.572 8.572 0 1 0 18.573 10 8.572 8.572 0 0 0 10 1.429Z" />
        </g>
    </svg>
);
