import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { TriangleDown } from "../../../icon/TriangleDown";
import { useClickOutside } from "../../../lib/click";

const DropdownWrapper = styled.div`
    position: relative;
`;

export const DropdownOptions = styled.div`
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    transform: translateY(calc(100% + 0.5rem));
    background-color: ${p => p.theme.gray50};
    box-shadow: ${p => p.theme.dropdownShadow};
    max-height: 75vh;
    overflow-y: auto;
    opacity: 0;
    animation: dropdown-fade-in 0.2s forwards;

    @media ${p => p.theme.bp.l} {
        min-width: 19rem;
    }

    @keyframes dropdown-fade-in {
        100% {
            opacity: 1;
        }
    }
`;

export const DropdownOption = styled.button`
    display: block;
    width: 100%;
    padding: 1.5rem;
    border-bottom: 0.1rem solid ${p => p.theme.gray300};
    transition: background-color 0.1s;
    will-change: background-color;

    &:last-child {
        border: none;
    }

    @media (hover: hover) {
        &:hover {
            background-color: ${p => p.theme.gray100};
        }
    }

    &:active {
        background-color: ${p => p.theme.gray100};
    }
`;

const IconChevron = styled(TriangleDown)`
    width: 0.8rem;
    height: 0.5rem;
    margin-left: 0.8rem;
`;

export const DropdownHead = styled.button<{ active: boolean }>`
    display: flex;
    align-items: center;
    height: 3.6rem;
    padding: 0 1.2rem;
    border: 0.1rem solid ${p => (p.active ? p.theme.gray600 : p.theme.gray300)};
    color: ${p => (p.active ? p.theme.gray900 : p.theme.gray700)};
    line-height: 1;
    transition: color 0.2s, border-color 0.2s;
    will-change: color, border-color;

    @media (hover: hover) {
        &:hover {
            color: ${p => p.theme.gray900};
            border-color: ${p => p.theme.gray600};
        }
    }
`;

export interface DropdownProps extends Filters.Filter {
    value?: string | null;
    onSelect: (key: string) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({ title, value, options, onSelect }) => {
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const optionsRef = useRef<HTMLDivElement | null>(null);
    const [active, setActive] = useState<boolean>(false);
    useClickOutside(dropdownRef, () => setActive(false));

    useEffect(() => {
        const el = optionsRef.current;

        if (!el) {
            return;
        }

        const { left, width } = el.getBoundingClientRect();
        const x = left + width;

        const diff = window.innerWidth - x;

        if (diff < 0) {
            el.style.right = 0 + "px";
            el.style.left = "auto";
        }
    }, [active]);

    return (
        <DropdownWrapper ref={dropdownRef}>
            <DropdownHead
                type="button"
                active={active}
                onClick={() => setActive(prevState => !prevState)}
            >
                {value ?? title}
                <IconChevron />
            </DropdownHead>
            {active && (
                <DropdownOptions ref={optionsRef}>
                    {Object.keys(options).map(optionValue => (
                        <DropdownOption
                            key={options[optionValue]}
                            type="button"
                            onClick={() => {
                                onSelect(optionValue);
                                setActive(false);
                            }}
                        >
                            {optionValue}
                        </DropdownOption>
                    ))}
                </DropdownOptions>
            )}
        </DropdownWrapper>
    );
};
