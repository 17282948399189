import React from "react";
import styled from "styled-components";
import { HeadingLLight, HeadingXXS, HeadingXXSLight } from "../../../css/typography";
import { WeatherIcon } from "../../../lib/weather";

const ItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ItemGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:last-child {
        text-align: right;
    }
`;

const ItemTemp = styled(HeadingLLight)`
    line-height: 1;
    margin-top: 0.25rem;
`;

const ItemDesc = styled(HeadingXXSLight)`
    text-transform: capitalize;
    margin-top: 0.1rem;
`;

export const WeatherItem: React.FC<OpenWeather.Response> = ({ name, sys, main, weather }) => {
    return (
        <ItemWrapper>
            <ItemGroup>
                <HeadingXXS>
                    {name}, {sys.country}
                </HeadingXXS>
                <ItemTemp>{Math.trunc(main.temp)}°C</ItemTemp>
            </ItemGroup>
            <ItemGroup>
                <WeatherIcon icon={weather[0].icon} />
                <ItemDesc>{weather[0].description}</ItemDesc>
            </ItemGroup>
        </ItemWrapper>
    );
};
