import React from "react";
import styled from "styled-components";
import { Content } from "../../../css/content";
import { ArrowRight } from "../../../icon/ArrowRight";
import { useTranslation } from "next-i18next";

const FilterWrapper = styled.div``;

const FilterHead = styled.div`
    display: flex;
    align-items: center;
    height: 5rem;
    border-bottom: 0.1rem solid ${p => p.theme.gray100};
    color: ${p => p.theme.gray600};
`;

const FilterContent = styled(Content)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ButtonBack = styled.button`
    display: flex;
    align-items: center;
`;

export const ButtonReset = styled.button``;

const ArrowIconLeft = styled(ArrowRight)`
    width: 2rem;
    height: 1.44rem;
    margin-right: 0.8rem;
    transform: scaleX(-1);
`;

const ArrowIcon = styled(ArrowRight)`
    width: 1.6rem;
    height: 1.15rem;
    color: ${p => p.theme.gray600};
`;

const FilterList = styled.div``;

export const FilterOption = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6rem;
    border-bottom: 0.1rem solid ${p => p.theme.gray100};
`;

export interface FilterMobileProps {
    facets: Filters.Facets;
    onSelect: (facetKey: string) => void;
    onReset: () => void;
    onClose: () => void;
}

export const FilterMobile: React.FC<FilterMobileProps> = ({
    facets,
    onSelect,
    onClose,
    onReset,
}) => {
    const { t } = useTranslation();

    return (
        <FilterWrapper>
            <FilterHead>
                <FilterContent>
                    <ButtonBack type="button" onClick={onClose}>
                        <ArrowIconLeft />
                        {t("back")}
                    </ButtonBack>
                    <ButtonReset type="button" onClick={onReset}>
                        {t("resetAll")}
                    </ButtonReset>
                </FilterContent>
            </FilterHead>
            <FilterList>
                {Object.keys(facets).map(facetKey => (
                    <FilterOption key={facetKey} onClick={() => onSelect(facetKey)}>
                        <FilterContent>
                            {t(facetKey)}
                            <ArrowIcon />
                        </FilterContent>
                    </FilterOption>
                ))}
            </FilterList>
        </FilterWrapper>
    );
};
