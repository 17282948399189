import { SVGProps } from "react";

export const Search = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.035 15.035" {...props}>
        <path
            d="M15.035 14.249l-4.2-4.2a6.127 6.127 0 10-.786.786l4.2 4.2zm-13.9-8.114a5.005 5.005 0 115 5.007 5.005 5.005 0 01-5-5.007z"
            fill="currentColor"
        />
    </svg>
);
