import React from "react";
import { Theme, useCustomTheme } from "../../../lib/context/theme/CustomThemeProvider";
import styled from "styled-components";
import { Sun } from "../../../icon/Sun";
import { Moon } from "../../../icon/Moon";
import { useTranslation } from "next-i18next";

const SwitchFrame = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    height: 3.7rem;
    background-color: ${p => p.theme.gray100};
    border-radius: 0.8rem;
`;

export const SwitchButton = styled.button`
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
`;

const SwitchKnob = styled.div<{ state: number }>`
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(${p => p.state * 100}%, 0, 0);
    width: 50%;
    height: 100%;
    padding: 0.3rem;
    transition: transform 0.2s;
    will-change: transform;
`;

const SwitchKnobInner = styled.div`
    height: 100%;
    width: 100%;
    background-color: ${p => p.theme.gray50};
    border-radius: 0.7rem;
    box-shadow: ${p => p.theme.switchShadow};
`;

const SunIcon = styled(Sun)`
    min-width: 2rem;
    width: 2rem;
    height: 2rem;
    margin-right: 0.65rem;
`;

const MoonIcon = styled(Moon)`
    min-width: 1.64rem;
    width: 1.64rem;
    height: 1.8rem;
    margin-right: 0.65rem;
`;

export interface SwitchThemeProps {
    theme: Theme;
    onToggle: () => void;
}

export const SwitchTheme: React.FC<SwitchThemeProps> = ({ theme, onToggle }) => {
    const { t } = useTranslation();

    return (
        <SwitchFrame>
            <SwitchButton disabled={"light" === theme} onClick={onToggle}>
                <SunIcon />
                {t("light")}
            </SwitchButton>
            <SwitchButton disabled={"dark" === theme} onClick={onToggle}>
                <MoonIcon />
                {t("dark")}
            </SwitchButton>
            <SwitchKnob state={"dark" === theme ? 1 : 0}>
                <SwitchKnobInner />
            </SwitchKnob>
        </SwitchFrame>
    );
};
