import React, { useState } from "react";
import styled from "styled-components";
import { Dropdown } from "../../atom/dropdown/Dropdown";
import { Filter } from "../../../icon/Filter";
import { FilterMobile } from "../filter-mobile/FilterMobile";
import { FilterMobileList } from "../../atom/filter-mobile-list/FilterMobileList";
import { useTranslation } from "next-i18next";

const FilterWrapper = styled.div``;

const FilterFrame = styled.div`
    display: none;
    align-items: center;
    gap: 0.5rem;

    @media ${p => p.theme.bp.m} {
        display: flex;
    }
`;

const FilterButton = styled.button`
    display: flex;
    align-items: center;
    color: ${p => p.theme.gray600};
    line-height: 1;

    @media ${p => p.theme.bp.m} {
        display: none;
    }
`;

const FilterIcon = styled(Filter)`
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.8rem;
`;

const FilterMobileWrapper = styled.div<{ active: boolean }>`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: ${p => (p.active ? "translate3d(0, 0, 0)" : "translate3d(-100%, 0, 0)")};
    padding-top: 5.6rem;
    background-color: ${p => p.theme.gray50};
    transition: transform 0.3s;
    will-change: transform;

    @media ${p => p.theme.bp.m} {
        display: none;
    }
`;

export interface FilterAreaProps {
    facets: Filters.Facets;
    onSelect: (facetKey: string, value: string) => void;
    onReset: () => void;
}

export const FilterArea: React.FC<FilterAreaProps> = ({ facets, onSelect, onReset }) => {
    const { t } = useTranslation();
    const [filterMobile, setFilterMobile] = useState<boolean>(false);
    const [activeFilter, setActiveFilter] = useState<string>("");

    const handleReset = () => {
        setFilterMobile(false);
        setActiveFilter("");
        onReset();
    };

    return (
        <FilterWrapper>
            <FilterFrame>
                {Object.keys(facets).map(facetKey => (
                    <Dropdown
                        key={facetKey}
                        title={t(facetKey)}
                        options={facets[facetKey].options}
                        onSelect={value => onSelect(facetKey, value)}
                    />
                ))}
            </FilterFrame>
            <FilterButton type="button" onClick={() => setFilterMobile(true)}>
                <FilterIcon />
                Filter
            </FilterButton>
            <FilterMobileWrapper active={filterMobile}>
                <FilterMobile
                    facets={facets}
                    onSelect={setActiveFilter}
                    onClose={() => setFilterMobile(false)}
                    onReset={handleReset}
                />
            </FilterMobileWrapper>
            {Object.keys(facets).map(facetKey => (
                <FilterMobileWrapper key={facetKey} active={activeFilter === facetKey}>
                    <FilterMobileList
                        options={facets[facetKey].options}
                        onSelect={facetValue => {
                            setFilterMobile(false);
                            setActiveFilter("");
                            onSelect(facetKey, facetValue);
                        }}
                        onClose={() => setActiveFilter("")}
                        onReset={handleReset}
                    />
                </FilterMobileWrapper>
            ))}
        </FilterWrapper>
    );
};
