import React from "react";
import styled from "styled-components";
import { Hamburger } from "../../../icon/Hamburger";
import { User } from "../../../icon/User";

export const HamburgerButton = styled.button<{ active: boolean }>`
    display: flex;
    align-items: center;
    padding: 0.5rem 0.6rem 0.5rem 1rem;
    border-radius: 10rem;
    border: 0.1rem solid ${p => (p.active ? p.theme.gray600 : p.theme.gray300)};
    transition: border-color 0.2s;
    will-change: border-color;

    @media (hover: hover) {
        &:hover {
            border-color: ${p => p.theme.gray600};
        }
    }
`;

const UserHamburgerIcon = styled(Hamburger)`
    width: 1.5rem;
    height: 1rem;
`;

const IconUser = styled(User)`
    width: 2.6rem;
    height: 2.6rem;
    margin-left: 1rem;
`;

export interface UserHamburgerProps {
    active: boolean;
    onClick: () => void;
}

export const UserHamburger: React.FC<UserHamburgerProps> = ({ onClick, active }) => {
    return (
        <HamburgerButton
            type="button"
            title="Toggle Notification Center"
            onClick={onClick}
            active={active}>
            <UserHamburgerIcon />
            <IconUser />
        </HamburgerButton>
    );
};
