import { SVGProps } from "react";

export const Feed = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 17.143" {...props}>
        <path
            d="M18.571 0H1.429A1.429 1.429 0 000 1.429v14.285a1.429 1.429 0 001.429 1.429h17.142A1.429 1.429 0 0020 15.714V1.429A1.429 1.429 0 0018.571 0zM1.429 1.429h11.428v14.285H1.429z"
            fill="currentColor"
        />
    </svg>
);
