export const renderHighlightByString = (text: string, str: string) => {
    const regex = new RegExp(str, "gi");
    text = text.replace(/(<mark class="highlight">|<\/mark>)/gim, "");
    return text.replace(regex, '<span class="highlight">$&</span>');
};

export const getFullName = (employee?: Partial<Organization.Employee> | null) => {
    const { firstName, lastName, academicTitle } = employee ?? {};
    return [academicTitle, firstName, lastName].filter(text => !!text).join(" ");
};

export const getInitialsFromEmployee = (employee?: Partial<Organization.Employee> | null) => {
    const { firstName, lastName } = employee ?? {};

    if (!firstName) {
        return "";
    }

    const first = firstName[0];

    if (!lastName) {
        return first;
    }

    const second = lastName[0];
    const third = lastName[1];

    if (!second) {
        return first;
    }

    if (!third) {
        return first + second;
    }

    return first + second + third;
};

export const getNameFromDepartment = (department: Organization.Department) => {
    const { designation } = department;

    if (!designation) {
        return "";
    }

    return designation.length > 20 ? designation.slice(0, 20) + "." : designation;
};
