import { MutableRefObject, useEffect } from "react";

export const useClickOutside = (ref: MutableRefObject<HTMLElement | null>, handler: () => void) => {
    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
                handler();
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
};
