import { SVGProps } from "react";

export const Sun = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" {...props}>
        <g>
            <path d="M9.285 0h1.43v3.546h-1.43z" />
            <path d="M14.058 4.926l2.507-2.507 1.012 1.01-2.508 2.508z" />
            <path d="M16.454 9.285H20v1.43h-3.546z" />
            <path d="M14.062 15.07l1.011-1.011 2.508 2.507-1.012 1.011z" />
            <path d="M9.285 16.454h1.43V20h-1.43z" />
            <path d="M2.428 16.57l2.507-2.507 1.012 1.01-2.508 2.508z" />
            <path d="M0 9.285h3.546v1.43H0z" />
            <path d="M2.424 3.434l1.011-1.011L5.943 4.93 4.93 5.941z" />
            <path d="M10 7.141A2.859 2.859 0 117.141 10 2.859 2.859 0 0110 7.141m0-1.43A4.289 4.289 0 1014.289 10 4.289 4.289 0 0010 5.711z" />
        </g>
    </svg>
);
