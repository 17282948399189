import React from "react";
import styled from "styled-components";

const weatherMap: Record<string, string> = {
    "01d": '<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="currentColor"><path d="M16 12.005a4 4 0 11-4 4 4 4 0 014-4m0-2a6 6 0 106 6 6 6 0 00-6-6zM5.394 6.813l1.414-1.414 3.506 3.506L8.9 10.319zM2 15.005h5v2H2zM5.394 25.197L8.9 21.691l1.414 1.414-3.506 3.506zM15 25.005h2v5h-2zM21.687 23.106l1.414-1.414 3.506 3.506-1.414 1.414zM25 15.005h5v2h-5zM21.687 8.904l3.506-3.506 1.414 1.414-3.506 3.506zM15 2.005h2v5h-2z"/><path fill="none" d="M0 0h32v32H0z"/></svg>',
    "01n": '<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="currentColor"><path d="M13.5 5.414a15.075 15.075 0 0011.6 18.194A11.113 11.113 0 0117.121 27c-.139 0-.278.005-.418 0A11.094 11.094 0 0113.5 5.414M14.98 3a1 1 0 00-.175.016A13.1 13.1 0 0016.63 29c.164.006.328 0 .491 0a13.072 13.072 0 0010.7-5.556 1.009 1.009 0 00-.783-1.564 13.08 13.08 0 01-11.15-17.5A1.015 1.015 0 0014.98 3z"/><path fill="none" d="M0 0h32v32H0z"/></svg>',
    "02d": '<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="currentColor"><path d="M27 15h4v2h-4zM23 7.587l3-3L27.414 6l-3 3zM15 1h2v4h-2zM4.586 26.001l3-3L9 24.415l-3 3zM4.586 6.001L6 4.587l3 3-1.414 1.414zM1 15h4v2H1z"/><path d="M26.794 20.342a6.962 6.962 0 00-1.868-3.267A8.486 8.486 0 0025 16a9 9 0 10-14.585 7.033A4.977 4.977 0 0015 30h10a4.995 4.995 0 001.794-9.658zM9 16a6.995 6.995 0 1113.986-.3A6.888 6.888 0 0020 15a7.04 7.04 0 00-6.794 5.342 4.986 4.986 0 00-1.644 1.048A6.967 6.967 0 019 16zm16 12H15a2.994 2.994 0 01-.7-5.908l.659-.157.1-.67a4.992 4.992 0 019.878 0l.1.67.659.157A2.994 2.994 0 0125 28z"/><path fill="none" d="M0 0h32v32H0z"/></svg>',
    "02n": '<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="currentColor"><path d="M30 19a4.974 4.974 0 00-3.206-4.658A6.971 6.971 0 0013.758 12.9a13.142 13.142 0 01.131-8.52A1.015 1.015 0 0012.98 3a.983.983 0 00-.175.016A13.1 13.1 0 0014.63 29c.164.006.328 0 .491 0a13.041 13.041 0 0010.29-5.039A4.99 4.99 0 0030 19zm-15.3 8a11.094 11.094 0 01-3.2-21.586 15.182 15.182 0 00.844 9.368A4.988 4.988 0 0015 24h7.677a11.1 11.1 0 01-7.556 3H14.7zM25 22H15a2.994 2.994 0 01-.7-5.908l.659-.157.1-.67a4.992 4.992 0 019.878 0l.1.67.659.157A2.995 2.995 0 0125 22z"/><path fill="none" d="M0 0h32v32H0z"/></svg>',
    "03d": '<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="currentColor"><path d="M16 7a7.664 7.664 0 011.511.15 8 8 0 016.349 6.34l.26 1.35 1.35.24a5.5 5.5 0 01-1 10.92H7.5a5.5 5.5 0 01-1-10.92l1.34-.24.26-1.35A8 8 0 0116 7m0-2a10 10 0 00-9.83 8.12A7.5 7.5 0 007.49 28h17a7.5 7.5 0 001.32-14.88 10 10 0 00-7.94-7.94A10.27 10.27 0 0016 5z"/><path fill="none" d="M0 0h32v32H0z"/></svg>',
    "03n": '<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="currentColor"><path d="M16 7a7.664 7.664 0 011.511.15 8 8 0 016.349 6.34l.26 1.35 1.35.24a5.5 5.5 0 01-1 10.92H7.5a5.5 5.5 0 01-1-10.92l1.34-.24.26-1.35A8 8 0 0116 7m0-2a10 10 0 00-9.83 8.12A7.5 7.5 0 007.49 28h17a7.5 7.5 0 001.32-14.88 10 10 0 00-7.94-7.94A10.27 10.27 0 0016 5z"/><path fill="none" d="M0 0h32v32H0z"/></svg>',
    "04d": '<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="currentColor"><path d="M30 15.5a6.532 6.532 0 00-5.2-6.364 8.994 8.994 0 00-17.6 0A6.532 6.532 0 002 15.5a6.454 6.454 0 001.689 4.35A5.983 5.983 0 008 30h11a5.976 5.976 0 005.609-8.1A6.505 6.505 0 0030 15.5zM19 28H8a3.992 3.992 0 01-.673-7.929l.663-.113.146-.656a5.5 5.5 0 0110.729 0l.146.656.663.113A3.993 3.993 0 0119 28zm4.5-8h-.055a5.956 5.956 0 00-2.8-1.756 7.5 7.5 0 00-14.3 0 5.988 5.988 0 00-1.031.407A4.445 4.445 0 014 15.5a4.517 4.517 0 014.144-4.481l.816-.064.1-.812a6.994 6.994 0 0113.884 0l.1.812.815.064A4.5 4.5 0 0123.5 20z"/><path fill="none" d="M0 0h32v32H0z"/></svg>',
    "04n": '<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="currentColor"><path d="M30 15.5a6.532 6.532 0 00-5.2-6.364 8.994 8.994 0 00-17.6 0A6.532 6.532 0 002 15.5a6.454 6.454 0 001.689 4.35A5.983 5.983 0 008 30h11a5.976 5.976 0 005.609-8.1A6.505 6.505 0 0030 15.5zM19 28H8a3.992 3.992 0 01-.673-7.929l.663-.113.146-.656a5.5 5.5 0 0110.729 0l.146.656.663.113A3.993 3.993 0 0119 28zm4.5-8h-.055a5.956 5.956 0 00-2.8-1.756 7.5 7.5 0 00-14.3 0 5.988 5.988 0 00-1.031.407A4.445 4.445 0 014 15.5a4.517 4.517 0 014.144-4.481l.816-.064.1-.812a6.994 6.994 0 0113.884 0l.1.812.815.064A4.5 4.5 0 0123.5 20z"/><path fill="none" d="M0 0h32v32H0z"/></svg>',
    "09d": '<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="currentColor"><path d="M17 30a1 1 0 01-.894-1.447l2-4a1 1 0 111.789.895l-2 4A1 1 0 0117 30zM8 30a1 1 0 01-.894-1.447l2-4a1 1 0 111.789.895l-2 4A1 1 0 018 30z"/><path d="M30 15.5a6.532 6.532 0 00-5.2-6.364 8.994 8.994 0 00-17.6 0 6.491 6.491 0 00-1.5 12.222l-1.6 3.194a1 1 0 101.789.895L7.617 22h6.764l-1.276 2.553a1 1 0 101.789.895L16.618 22h6.764l-1.276 2.553a1 1 0 101.789.895l1.944-3.89A6.506 6.506 0 0030 15.5zM23.5 20h-15a4.5 4.5 0 01-.356-8.981l.816-.064.1-.812a6.994 6.994 0 0113.884 0l.1.812.815.064A4.5 4.5 0 0123.5 20z"/><path fill="none" d="M0 0h32v32H0z"/></svg>',
    "09n": '<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="currentColor"><path d="M17 30a1 1 0 01-.894-1.447l2-4a1 1 0 111.789.895l-2 4A1 1 0 0117 30zM8 30a1 1 0 01-.894-1.447l2-4a1 1 0 111.789.895l-2 4A1 1 0 018 30z"/><path d="M30 15.5a6.532 6.532 0 00-5.2-6.364 8.994 8.994 0 00-17.6 0 6.491 6.491 0 00-1.5 12.222l-1.6 3.194a1 1 0 101.789.895L7.617 22h6.764l-1.276 2.553a1 1 0 101.789.895L16.618 22h6.764l-1.276 2.553a1 1 0 101.789.895l1.944-3.89A6.506 6.506 0 0030 15.5zM23.5 20h-15a4.5 4.5 0 01-.356-8.981l.816-.064.1-.812a6.994 6.994 0 0113.884 0l.1.812.815.064A4.5 4.5 0 0123.5 20z"/><path fill="none" d="M0 0h32v32H0z"/></svg>',
    "10d": '<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="currentColor"><path d="M8.508 32a1.013 1.013 0 01-.448-.105 1 1 0 01-.449-1.342l1.493-3a1 1 0 111.794.895l-1.493 3a1 1 0 01-.9.553zM26.465 16.879l1.414-1.414 2.122 2.12L28.587 19zM29 10h3v2h-3zM26.464 5.122l2.121-2.121L30 4.415l-2.122 2.121zM20 0h2v3h-2zM12 4.415l1.414-1.414 2.122 2.121-1.415 1.414zM27 11a6 6 0 00-11.367-2.669A7.349 7.349 0 0013.5 8a7.551 7.551 0 00-7.149 5.244A5.993 5.993 0 008 25h7.382l-1.276 2.553a1 1 0 101.789.895L17.618 25H19a5.985 5.985 0 005.216-8.946A5.994 5.994 0 0027 11zm-8 12H8a3.993 3.993 0 01-.673-7.929l.663-.113.146-.656a5.5 5.5 0 0110.729 0l.146.656.663.113A3.992 3.992 0 0119 23zm3.927-8.511a5.96 5.96 0 00-2.278-1.245 7.516 7.516 0 00-3.185-4.069 3.987 3.987 0 115.463 5.314z"/><path fill="none" d="M0 0h32v32H0z"/></svg>',
    "10n": '<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="currentColor"><path d="M8.508 32a1.012 1.012 0 01-.448-.105 1 1 0 01-.449-1.342l1.493-3a1 1 0 111.794.895l-1.493 3a1 1 0 01-.897.552zM29.844 13.035a1.518 1.518 0 00-1.231-.866 5.359 5.359 0 01-3.413-1.716 6.465 6.465 0 01-1.281-6.393 1.6 1.6 0 00-.3-1.546 1.454 1.454 0 00-1.359-.492h-.019A7.855 7.855 0 0016.135 8.5 7.373 7.373 0 0013.5 8a7.551 7.551 0 00-7.149 5.244A5.993 5.993 0 008 25h7.382l-1.276 2.553a1 1 0 101.789.895L17.618 25H19a5.955 5.955 0 005.88-7.146 7.5 7.5 0 004.867-3.3 1.538 1.538 0 00.1-1.52zM19 23H8a3.993 3.993 0 01-.673-7.929l.663-.113.146-.656a5.5 5.5 0 0110.729 0l.146.656.663.113A3.993 3.993 0 0119 23zm5.151-7.048a5.964 5.964 0 00-3.5-2.708 7.508 7.508 0 00-2.622-3.694A6.01 6.01 0 0121.8 4.216a8.458 8.458 0 001.94 7.6 7.4 7.4 0 003.9 2.228 5.439 5.439 0 01-3.489 1.911z"/><path fill="none" d="M0 0h32v32H0z"/></svg>',
    "11d": '<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="currentColor"><path d="M21 30a1 1 0 01-.894-1.447l2-4a1 1 0 111.789.895l-2 4A1 1 0 0121 30zM9 32a1 1 0 01-.894-1.447l2-4a1 1 0 011.789.895l-2 4A1 1 0 019 32zM15.9 30.5l-1.735-1L17.31 24h-6l4.855-8.5 1.735 1-3.144 5.5h6z"/><path d="M24.8 9.136a8.994 8.994 0 00-17.6 0A6.493 6.493 0 007.43 21.9l-1.324 2.653a1 1 0 101.789.895l2-4a1 1 0 00-.447-1.342A.979.979 0 009 20.01V20h-.5a4.5 4.5 0 01-.356-8.981l.816-.064.1-.812a6.994 6.994 0 0113.884 0l.1.812.815.064A4.5 4.5 0 0123.5 20H23v2h.5a6.5 6.5 0 001.3-12.864z"/><path fill="none" d="M0 0h32v32H0z"/></svg>',
    "11n": '<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="currentColor"><path d="M21 30a1 1 0 01-.894-1.447l2-4a1 1 0 111.789.895l-2 4A1 1 0 0121 30zM9 32a1 1 0 01-.894-1.447l2-4a1 1 0 011.789.895l-2 4A1 1 0 019 32zM15.9 30.5l-1.735-1L17.31 24h-6l4.855-8.5 1.735 1-3.144 5.5h6z"/><path d="M24.8 9.136a8.994 8.994 0 00-17.6 0A6.493 6.493 0 007.43 21.9l-1.324 2.653a1 1 0 101.789.895l2-4a1 1 0 00-.447-1.342A.979.979 0 009 20.01V20h-.5a4.5 4.5 0 01-.356-8.981l.816-.064.1-.812a6.994 6.994 0 0113.884 0l.1.812.815.064A4.5 4.5 0 0123.5 20H23v2h.5a6.5 6.5 0 001.3-12.864z"/><path fill="none" d="M0 0h32v32H0z"/></svg>',
    "13d": '<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="currentColor"><path d="M21.415 12H28v-2h-4.585L28 5.415 26.586 4 22 8.587V4h-2v6.587L18.587 12H17V8h-2v4h-1.587L12 10.587V4h-2v4.587L5.414 4 4 5.415 8.585 10H4v2h6.585L12 13.415V15H8v2h4v1.587L10.587 20H4v2h4.587L4 26.586 5.414 28 10 23.415V28h2v-6.585L13.415 20H15v4h2v-4h1.585L20 21.415V28h2v-4.585L26.585 28 28 26.586 23.413 22H28v-2h-6.587L20 18.587V17h4v-2h-4v-1.585zM18 18h-4v-4h4z"/><path fill="none" d="M0 0h32v32H0z"/></svg>',
    "13n": '<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="currentColor"><path d="M21.415 12H28v-2h-4.585L28 5.415 26.586 4 22 8.587V4h-2v6.587L18.587 12H17V8h-2v4h-1.587L12 10.587V4h-2v4.587L5.414 4 4 5.415 8.585 10H4v2h6.585L12 13.415V15H8v2h4v1.587L10.587 20H4v2h4.587L4 26.586 5.414 28 10 23.415V28h2v-6.585L13.415 20H15v4h2v-4h1.585L20 21.415V28h2v-4.585L26.585 28 28 26.586 23.413 22H28v-2h-6.587L20 18.587V17h4v-2h-4v-1.585zM18 18h-4v-4h4z"/><path fill="none" d="M0 0h32v32H0z"/></svg>',
    "50d": '<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="currentColor"><path d="M19 28H3a1 1 0 010-2h16a1 1 0 010 2zM15 20H3a1 1 0 010-2h12a1 1 0 010 2zM25.793 22.379l2.828 2.828-1.414 1.415-2.828-2.829zM28 15h4v2h-4zM24.379 8.207l2.828-2.828 1.415 1.414-2.829 2.828zM17 2h2v4h-2zM10.207 9.621L7.379 6.793l1.414-1.415 2.828 2.829z"/><path d="M18 8a8.009 8.009 0 00-8 8h2a6 6 0 116 6H7a1 1 0 000 2h11a8 8 0 000-16z"/><path fill="none" d="M0 0h32v32H0z"/></svg>',
    "50n": '<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="currentColor"><path d="M29 22H13a1 1 0 000 2h9.677a11.1 11.1 0 01-7.556 3h-.418A11.094 11.094 0 0111.5 5.414 15.153 15.153 0 0011.454 12H13.5a13.15 13.15 0 01.39-7.621A1.015 1.015 0 0012.98 3a.983.983 0 00-.175.016A13.1 13.1 0 0014.63 29c.164.006.328 0 .491 0a13.04 13.04 0 0010.257-5H29a1 1 0 000-2z"/><path d="M27 20H17a1 1 0 010-2h10a1 1 0 010 2zM25 16H13a1 1 0 010-2h12a1 1 0 010 2z"/><path fill="none" d="M0 0h32v32H0z"/></svg>',
};

const Icon = styled.span`
    display: flex;
    justify-content: flex-end;
`;

interface WeatherIconProps {
    icon: string;
}

export const WeatherIcon: React.FC<WeatherIconProps> = ({ icon }) => {
    return <Icon dangerouslySetInnerHTML={{ __html: weatherMap[icon] }} />;
};
