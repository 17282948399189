import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { SEARCH_TAB_NEWS, searchTabsFromSuggestions } from "../../../lib/search";
import { SuggestionCategories } from "../../molecule/suggestion-categories/SuggestionCategories";

const SuggestionsWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 1.5rem 0 0;

    @media ${p => p.theme.bp.m} {
        background-color: ${p => p.theme.gray50};
        box-shadow: 0 0.2rem 2.4rem rgba(0, 0, 0, 0.15);
    }
`;

const SuggestionsHead = styled.div`
    position: relative;
    display: flex;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.2rem;
        background-color: ${p => p.theme.gray200};
    }

    @media ${p => p.theme.bp.m} {
        margin: 0 1.5rem;
    }
`;

const SuggestionTab = styled.button<{ $active: boolean }>`
    position: relative;
    z-index: 1;
    text-transform: capitalize;
    margin-right: 1.4rem;
    padding-bottom: 0.6rem;
    color: ${p => (p.$active ? p.theme.gray900 : p.theme.gray600)};
    border-bottom: 0.2rem solid ${p => (p.$active ? p.theme.primary : "transparent")};
    transition: color 0.1s;
    will-change: color, border-color;

    @media (hover: hover) {
        &:hover {
            color: ${p => p.theme.gray900};
        }
    }

    &:active {
        color: ${p => p.theme.gray900};
    }

    &:last-child {
        margin-right: 0;
    }
`;

const SuggestionsList = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 2rem 0 1.5rem;
`;

export interface SuggestionsProps {
    searchStr: string;
    initialTab?: Search.Tab;
    suggestions: Search.Suggestion[];
    onClick: (suggestion: Search.Suggestion) => void;
}

export const Suggestions: React.FC<SuggestionsProps> = ({
    searchStr,
    suggestions,
    initialTab,
    onClick,
}) => {
    const [activeTab, setActiveTab] = useState<Search.Tab>(initialTab || SEARCH_TAB_NEWS);

    const searchTabs = useMemo(() => {
        return searchTabsFromSuggestions(suggestions);
    }, [suggestions]);

    return (
        <SuggestionsWrapper>
            <SuggestionsHead>
                {Object.keys(searchTabs).map(groupKey => (
                    <SuggestionTab
                        key={groupKey}
                        type="button"
                        $active={groupKey === activeTab}
                        onClick={() => setActiveTab(groupKey as Search.Tab)}>
                        {groupKey}
                    </SuggestionTab>
                ))}
            </SuggestionsHead>
            <SuggestionsList>
                {Object.keys(searchTabs).map(groupKey => {
                    return (
                        groupKey === activeTab && (
                            <SuggestionCategories
                                key={groupKey}
                                searchStr={searchStr}
                                suggestions={searchTabs[groupKey]}
                                onClick={onClick}
                            />
                        )
                    );
                })}
            </SuggestionsList>
        </SuggestionsWrapper>
    );
};
