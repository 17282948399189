import { SVGProps } from "react";

export const SignOut = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.4 20.004" {...props}>
        <g fill="currentColor">
            <path d="M1.45 20h8.7a1.441 1.441 0 001.45-1.429v-2.139h-1.45v2.143h-8.7V1.429h8.7v2.143h1.45V1.429A1.441 1.441 0 0010.152 0H1.45A1.441 1.441 0 000 1.429v17.146A1.441 1.441 0 001.45 20z" />
            <path d="M12.025 13.327l2.6-2.6H4.348v-1.45h10.277l-2.6-2.6 1.023-1.027 4.351 4.351-4.351 4.349z" />
        </g>
    </svg>
);
