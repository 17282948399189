import { SVGProps } from "react";

export const SidebarHamburger = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.5 17.5" {...props}>
        <g
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5">
            <path d="M.75 8.75h24" />
            <path d="M.75.75h24" />
            <path d="M.75 16.75h11" />
        </g>
    </svg>
);
