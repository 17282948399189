import React from "react";
import styled from "styled-components";
import { HeadingSLight } from "../../../css/typography";
import { WeatherItem } from "../../atom/weather-item/WeatherItem";
import { useTranslation } from "next-i18next";

const WidgetWrapper = styled.div`
    width: 100%;
    background-color: ${p => p.theme.gray50};
    border-radius: 0.8rem;
    padding: 2rem 0;
`;

const WidgetHead = styled.div`
    padding: 0 2rem;
    margin-bottom: 2rem;
`;

const WeatherList = styled.div``;

const ItemWrapper = styled.div`
    padding: 1.4rem 2rem;
    border-bottom: 0.1rem solid ${p => p.theme.gray100};

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
        border: none;
    }
`;

interface WidgetWeatherProps {
    weather: OpenWeather.Response[];
}

export const WidgetWeather: React.FC<WidgetWeatherProps> = ({ weather }) => {
    const { t } = useTranslation();

    return (
        <WidgetWrapper>
            <WidgetHead>
                <HeadingSLight>{t("weather")}</HeadingSLight>
            </WidgetHead>
            <WeatherList>
                {weather.map(item => (
                    <ItemWrapper key={item.id}>
                        <WeatherItem {...item} />
                    </ItemWrapper>
                ))}
            </WeatherList>
        </WidgetWrapper>
    );
};
