import React from "react";
import styled from "styled-components";
import { HeadingXXSLight } from "../../../css/typography";
import { useTranslation } from "next-i18next";

const StateWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StateIndicator = styled.div<{ active: boolean }>`
    width: 1rem;
    height: 1rem;
    margin-right: 0.4rem;
    border-radius: 50%;
    background-color: ${p => (p.active ? p.theme.green700 : p.theme.red700)};
`;

export interface UserStateProps {
    active: boolean;
}

export const UserState: React.FC<UserStateProps> = ({ active }) => {
    const { t } = useTranslation();

    return (
        <StateWrapper>
            <StateIndicator active={active} />
            <HeadingXXSLight>{active ? t("active") : t("inactive")}</HeadingXXSLight>
        </StateWrapper>
    );
};
