import { SVGProps } from "react";

export const Filter = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
        <path
            d="M10.5 18h-3A1.5 1.5 0 016 16.5v-5.693L.443 5.25A1.5 1.5 0 010 4.193V1.5A1.5 1.5 0 011.5 0h15A1.5 1.5 0 0118 1.5v2.693a1.5 1.5 0 01-.443 1.057l-4.19 4.19L12 10.807V16.5a1.5 1.5 0 01-1.5 1.5zm-9-16.5v2.693l6 6V16.5h3v-6.307l6-6V1.5z"
            fill="currentColor"
        />
    </svg>
);
