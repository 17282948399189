import { SVGProps } from "react";

export const LogoMark = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 28.111" {...props}>
        <defs>
            <clipPath id="a_mark">
                <path fill="none" d="M0 0h20v27.852H0z" />
            </clipPath>
            <clipPath id="b_mark">
                <path fill="none" d="M0 0h20v27.852H0z" />
            </clipPath>
        </defs>
        <g>
            <g>
                <g clipPath="url(#a_mark)">
                    <path fill="#ea5b0c" d="M0 22.436h5.594v5.419H0z" />
                </g>
                <g transform="translate(0 .259)" clipPath="url(#b_mark)">
                    <path d="M0 .003v5.419h19.109L19.99.065V.003z" fill="#ea5b0c" />
                </g>
                <g transform="translate(0 .259)" clipPath="url(#b_mark)">
                    <path d="M0 11.28v5.357h17.161l.88-5.357z" fill="#ea5b0c" />
                </g>
            </g>
        </g>
    </svg>
);
