import React, { useRef } from "react";
import styled from "styled-components";
import { renderHighlightByString } from "../../../lib/string";
import { News } from "../../../icon/News";
import { HeadingXXSLight } from "../../../css/typography";
import { SUGGESTION_TYPE_DEPARTMENT, SUGGESTION_TYPE_EMPLOYEE } from "../../../lib/search";
import { Avatar } from "../avatar/Avatar";
import { Department } from "../../../icon/Department";

const SuggestionButton = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    transition: background-color 0.1s;
    will-change: background-color;

    .highlight {
        color: ${p => p.theme.orange700};
    }

    @media (hover: hover) {
        &:hover {
            background-color: ${p => p.theme.gray100};
        }
    }

    &:active {
        background-color: ${p => p.theme.gray100};
    }

    @media ${p => p.theme.bp.m} {
        padding: 0.8rem 1.5rem;
    }
`;

const SuggestionTitle = styled(HeadingXXSLight)`
    display: block;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const SuggestionDescription = styled(HeadingXXSLight)`
    display: block;
    flex: 1;
    color: ${p => p.theme.gray600};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const SuggestionColumn = styled.div`
    max-width: 100%;
    display: flex;
    align-items: center;
    margin-right: 2rem;
`;

const IconNews = styled(News)`
    width: 1.2rem;
    height: 1rem;
    margin-right: 1.2rem;
`;

const IconDepartment = styled(Department)`
    width: 1.7rem;
    height: 1.7rem;
    margin-right: 1rem;
`;

const AvatarWrapper = styled.div`
    margin-right: 0.5rem;
`;

export interface SuggestionProps extends Search.Suggestion {
    searchStr: string;
    onClick: () => void;
}

export const Suggestion: React.FC<SuggestionProps> = ({
    searchStr,
    title,
    description,
    link,
    type,
    onClick,
}) => {
    const renderIcon = () => {
        switch (type) {
            case SUGGESTION_TYPE_EMPLOYEE:
                return (
                    <AvatarWrapper>
                        <Avatar name={title} />
                    </AvatarWrapper>
                );
            case SUGGESTION_TYPE_DEPARTMENT:
                return <IconDepartment />;
            default:
                return <IconNews />;
        }
    };

    return link ? (
        <SuggestionButton as="a" href={link} target="_blank" rel="noreferrer noopener">
            <SuggestionColumn>
                {renderIcon()}
                <SuggestionTitle
                    dangerouslySetInnerHTML={{ __html: renderHighlightByString(title, searchStr) }}
                />
            </SuggestionColumn>
            <SuggestionDescription>{description}</SuggestionDescription>
        </SuggestionButton>
    ) : (
        <SuggestionButton type="button" onMouseDown={onClick}>
            <SuggestionColumn>
                {renderIcon()}
                <SuggestionTitle
                    dangerouslySetInnerHTML={{ __html: renderHighlightByString(title, searchStr) }}
                />
            </SuggestionColumn>
            <SuggestionDescription>{description}</SuggestionDescription>
        </SuggestionButton>
    );
};
