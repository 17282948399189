import React from "react";
import styled from "styled-components";
import Link from "next/link";
import { HeadingSLight } from "../../../css/typography";

export const ItemWrapper = styled.button<{ active?: boolean; isSecondary?: boolean }>`
    position: relative;
    display: block;
    width: 100%;
    padding: 0.8rem 0;
    transition: background-color 0.2s;
    will-change: background-color;
    color: ${p => (p.isSecondary ? p.theme.gray600 : p.theme.gray700)};
    color: ${p => p.active && !p.isSecondary && p.theme.gray900};
    background-color: ${p => !p.isSecondary && p.active && p.theme.gray100};

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: ${p => (!p.isSecondary && p.active ? "block" : "none")};
        height: 100%;
        width: 0.2rem;
        background-color: ${p => p.theme.orange700};
    }

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 2.5rem;
        transform: translate(-50%, -50%);
        display: ${p => (p.isSecondary && p.active ? "block" : "none")};
        width: 3.4rem;
        height: 3.4rem;
        background-color: ${p => p.theme.gray100};
        border-radius: 0.4rem;
    }

    @media (hover: hover) {
        &:hover {
            background-color: ${p => p.theme.gray100};
        }
    }
`;

const ItemInner = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const IconWrapper = styled.div<{ active?: boolean }>`
    display: flex;
    justify-content: center;
    width: 5rem;
    color: ${p => p.active && p.theme.orange700};
    transition: color 0.2s;
    will-change: color;
`;

export interface SidebarItemProps {
    text: string;
    action: string | (() => void);
    title?: string;
    active?: boolean;
    locale?: string | false;
    isSecondary?: boolean;
}

export const SidebarItem: React.FC<SidebarItemProps> = ({
    text,
    title,
    active,
    isSecondary,
    action,
    locale,
    children,
}) => {
    return "string" === typeof action ? (
        <Link href={action} locale={locale} passHref>
            <ItemWrapper
                as="a"
                title={title}
                name={title}
                active={active}
                isSecondary={isSecondary}>
                <ItemInner>
                    <IconWrapper active={active}>{children}</IconWrapper>
                    <HeadingSLight>{text}</HeadingSLight>
                </ItemInner>
            </ItemWrapper>
        </Link>
    ) : (
        <ItemWrapper
            title={title}
            name={title}
            active={active}
            isSecondary={isSecondary}
            onClick={action}>
            <ItemInner>
                <IconWrapper active={active}>{children}</IconWrapper>
                <HeadingSLight>{text}</HeadingSLight>
            </ItemInner>
        </ItemWrapper>
    );
};
