import { gql } from "@apollo/client";

export const LAYOUT = gql`
    query GetLayout {
        sidebar @client
        mobileSidebar @client
        notificationCenter @client
        newsFeed @client
        newsType @client
    }
`;
