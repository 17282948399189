import { SVGProps } from "react";

export const User = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
        <path
            d="M20 0a20 20 0 1020 20A20.022 20.022 0 0020 0zm0 36.6a16.564 16.564 0 01-12.984-6.286 16.242 16.242 0 018.433-5.752 8.5 8.5 0 119.1 0 16.235 16.235 0 018.434 5.752A16.567 16.567 0 0120 36.6z"
            fill="#717171"
        />
    </svg>
);
