import React, { useMemo } from "react";
import styled from "styled-components";
import { searchCategoriesFromSuggestions } from "../../../lib/search";
import { Suggestion } from "../../atom/suggestion/Suggestion";
import { HeadingXXSLight, Meta } from "../../../css/typography";

const SuggestionsCategory = styled.div`
    margin-bottom: 2rem;

    &:last-child {
        margin-bottom: 0;
    }
`;

const CategoryTitle = styled(Meta)`
    margin-bottom: 1.4rem;
    text-transform: uppercase;
    color: ${p => p.theme.gray700};

    @media ${p => p.theme.bp.m} {
        padding: 0 1.5rem;
    }
`;

const SuggestionsInfo = styled(HeadingXXSLight)`
    display: block;
    text-align: center;
    padding-bottom: 2rem;
    color: ${p => p.theme.gray600};
`;

export interface SuggestionsCategoriesProps {
    searchStr: string;
    suggestions: Search.Suggestion[];
    onClick: (suggestion: Search.Suggestion) => void;
}

export const SuggestionCategories: React.FC<SuggestionsCategoriesProps> = ({
    searchStr,
    suggestions,
    onClick,
}) => {
    const searchCategories = useMemo(() => {
        return searchCategoriesFromSuggestions(suggestions);
    }, [suggestions]);

    return (
        <>
            {Object.keys(searchCategories).length ? (
                Object.keys(searchCategories).map(categoryKey => (
                    <SuggestionsCategory key={categoryKey}>
                        <CategoryTitle>{categoryKey}</CategoryTitle>
                        {searchCategories[categoryKey].map(suggestion => (
                            <Suggestion
                                key={suggestion.id}
                                searchStr={searchStr}
                                onClick={() => onClick(suggestion)}
                                {...suggestion}
                            />
                        ))}
                    </SuggestionsCategory>
                ))
            ) : (
                <SuggestionsInfo>No results found</SuggestionsInfo>
            )}
        </>
    );
};
