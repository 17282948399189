import { SVGProps } from "react";

export const ReturnArrow = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
        <path
            fill="currentColor"
            d="M2.1,5l2.1-2.2v7.9c0,0.3,0.1,0.7,0.4,0.9s0.6,0.3,0.9,0.4H11v-1.5H5.7V2.8l2.1,2.1L9,3.8L5,0L1,3.8L2.1,5z"
        />
    </svg>
);
