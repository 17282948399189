import { SVGProps } from "react";

export const News = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16.666" {...props}>
        <path
            d="M18.333 0v1.808L1.367 4.892A1.667 1.667 0 000 6.558v3.617a1.667 1.667 0 001.367 1.667l1.967.292V15A1.667 1.667 0 005 16.666h6.667A1.667 1.667 0 0013.333 15v-1.05l5 .908v1.808H20V0zm-6.667 15H5v-2.558l6.667 1.208zm-10-4.858V6.525L18.333 3.5v9.667z"
            fill="currentColor"
        />
    </svg>
);
