import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { WidgetUser } from "../../molecule/widget-user/WidgetUser";
import { Content } from "../../../css/content";
import { WidgetWeather } from "../../molecule/widget-weather/WidgetWeather";
import { getCurrentWeather } from "../../../lib/api/weather";
import { useRouter } from "next/router";
import { defaultLocale } from "../../../lib/language";
import { getFullName } from "../../../lib/string";

const FrameContent = styled(Content)`
    position: relative;
`;

const WidgetWrapper = styled.div`
    margin-bottom: 1rem;
    pointer-events: auto;

    &:last-child {
        margin-bottom: 0;
    }
`;

export interface NotificationCenterProps {
    session: Auth.Session;
}

export const NotificationCenter: React.FC<NotificationCenterProps> = ({ session }) => {
    const router = useRouter();
    const [weather, setWeather] = useState<OpenWeather.Response[] | null>(null);

    useEffect(() => {
        getCurrentWeather(router.locale ?? defaultLocale).then(setWeather);
    }, []);

    const handleSignOut = () => {
        window.location.href = `/api/auth/logout`;
    };

    return (
        <FrameContent $breakTablet>
            <WidgetWrapper>
                <WidgetUser
                    name={getFullName({
                        firstName: session.firstName,
                        lastName: session.lastName,
                    })}
                    onSignOut={handleSignOut}
                    active
                />
            </WidgetWrapper>
            {weather && (
                <WidgetWrapper>
                    <WidgetWeather weather={weather} />
                </WidgetWrapper>
            )}
        </FrameContent>
    );
};
