import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { HeadingS } from "../../../css/typography";

const AlertWrapper = styled.div`
    width: 100%;
    max-height: 0;
    overflow: hidden;
    animation: grow 0.25s forwards;

    @keyframes grow {
        100% {
            max-height: 4rem;
        }
    }
`;

const AlertFrame = styled.button`
    display: flex;
    align-items: center;
    height: 4rem;
    width: 100%;
    background: ${p => p.theme.primaryGradient};
    color: ${p => p.theme.white};
`;

const AlertTextWrapper = styled.div<{ $duration: number }>`
    width: fit-content;
    display: flex;
    position: relative;
    transform: translate3d(calc(-25% + 20vw), 0, 0);
    animation-name: marquee;
    animation-duration: ${p => p.$duration}s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    pointer-events: none;

    @keyframes marquee {
        0% {
            transform: translate3d(calc(-25% + 20vw), 0, 0);
        }

        100% {
            transform: translate3d(calc(-50% + 20vw), 0, 0);
        }
    }
`;

export const AlertText = styled(HeadingS)`
    display: block;
    text-align: center;
    line-height: 1;
    padding: 0 2rem;
    white-space: nowrap;
`;

export interface AlertProps {
    title: string;
    onClick: () => void;
}

export const Alert: React.FC<AlertProps> = ({ title, onClick }) => {
    const trackRef = useRef<HTMLDivElement | null>(null);
    const [duration, setDuration] = useState(20);

    useEffect(() => {
        if (!trackRef.current) {
            return;
        }

        const { width } = trackRef.current.getBoundingClientRect();

        setDuration((width * 20) / 5000);
    }, []);

    return (
        <AlertWrapper>
            <AlertFrame type="button" onClick={onClick}>
                <AlertTextWrapper ref={trackRef} $duration={duration}>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                    <AlertText>+++ {title} +++</AlertText>
                </AlertTextWrapper>
            </AlertFrame>
        </AlertWrapper>
    );
};
