import { SVGProps } from "react";

export const HelpCenter = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.999 20" {...props}>
        <g transform="translate(20968 13615)" fill="currentColor">
            <path d="M-20958-13615a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm0 18.571a8.571 8.571 0 1 1 8.571-8.571 8.571 8.571 0 0 1-8.571 8.571Z" />
            <circle cx="1.071" cy="1.071" transform="translate(-20959.072 -13600.715)" r="1.071" />
            <path d="M-20957.286-13610.714h-1.072a3.207 3.207 0 0 0-3.214 3.214v.357h1.429v-.357a1.786 1.786 0 0 1 1.786-1.786h1.071a1.786 1.786 0 0 1 0 3.571h-1.429v3.214h1.429v-1.784a3.215 3.215 0 0 0 0-6.429Z" />
        </g>
    </svg>
);
