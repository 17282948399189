import React, { ReactNode, useEffect } from "react";
import styled from "styled-components";
import { Sidebar } from "../../molecule/sidebar/Sidebar";
import { Navigation } from "../../organism/navigation/Navigation";
import { NotificationCenter } from "../../organism/notification-center/NotificationCenter";
import { NewsNavigation } from "../../molecule/news-navigation/NewsNavigation";
import { Alert } from "../../atom/alert/Alert";
import { useRouter } from "next/router";
import { useQuery } from "@apollo/client";
import {
    Layout,
    mobileSidebarVar,
    newsTypeVar,
    toggleMobileSidebar,
    toggleNotificationCenter,
    toggleSidebar,
    toggleNewsFeed,
    readStorageForSidebar,
} from "../../../lib/api/cache/options";
import { LAYOUT } from "../../../lib/api/cache/query";
import { useSession } from "../../../lib/auth/client/SessionContext";
import { useNews } from "../../../lib/context/news/NewsContext";
import { useOrganization } from "../../../lib/context/organization/OrganizationContext";
import {
    activeTabFromPath,
    SUGGESTION_TYPE_DEPARTMENT,
    SUGGESTION_TYPE_EMPLOYEE,
    SUGGESTION_TYPE_NEWS,
} from "../../../lib/search";
import { NEWS_TYPE_MAIN } from "../../../lib/context/news/NewsProvider";
import { getDepartmentQuery, getEmployeeQuery } from "../../../lib/chart";

const TemplateWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 40rem;
    overflow: hidden;

    @media ${p => p.theme.bp.l} {
        min-height: 60rem;
    }

    @media print {
        display: none;
    }
`;

const TemplateInner = styled.div<{ $hasAlert: boolean }>`
    display: flex;
    height: ${p => (p.$hasAlert ? "calc(100% - 4rem)" : "100%")};
`;

const TemplateFrame = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`;

const TemplateStageWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`;

const TemplateStage = styled.div`
    overflow-y: auto;
    flex: 1;
`;

const SidebarWrapper = styled.div`
    display: none;

    @media ${p => p.theme.bp.m} {
        display: block;
    }
`;

const MobileSidebar = styled.div<{ active: boolean }>`
    position: absolute;
    z-index: 3;
    top: 0;
    right: 100%;
    height: 100%;
    width: 100%;
    transform: ${p => (p.active ? "translate3d(100%, 0, 0)" : "translate3d(0, 0, 0)")};
    transition: transform 0.3s;
    will-change: transform;
    pointer-events: none;

    @media ${p => p.theme.bp.m} {
        display: none;
    }
`;

const Overlay = styled.button<{ active: boolean }>`
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${p => p.theme.overlay};
    opacity: ${p => (p.active ? 1 : 0)};
    pointer-events: ${p => (p.active ? "auto" : "none")};
    transition: opacity 0.2s;
    will-change: opacity;
`;

const SidebarOverlay = styled(Overlay)`
    z-index: 2;

    @media ${p => p.theme.bp.m} {
        display: none;
    }
`;

const NavigationWrapper = styled.div`
    @media ${p => p.theme.bp.l} {
        display: none;
    }
`;

const NotificationWrapper = styled.div<{ $active: boolean }>`
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transform: ${p => (p.$active ? "translate3d(0, 0, 0)" : "translate3d(100%, 0, 0)")};
    pointer-events: none;
    transition: transform 0.3s;
    will-change: transform;

    @media ${p => p.theme.bp.m} {
        width: 29rem;
    }
`;

const NotificationStage = styled.div<{ $hasAlert: boolean }>`
    padding: ${p => (p.$hasAlert ? "10.6rem 0 6.6rem" : "6.6rem 0")};
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    pointer-events: auto;

    @media ${p => p.theme.bp.m} {
        padding-right: 1rem;
    }
`;

const AlertWrapper = styled.div`
    position: relative;
    z-index: 4;
`;

export interface TemplateProps {
    isNews?: boolean;
    isOrga?: boolean;
    renderNavigationContent?: ReactNode;
    hideGlobalSearch?: boolean;
}

export const Template: React.FC<TemplateProps> = ({
    isNews,
    isOrga,
    hideGlobalSearch,
    renderNavigationContent,
    children,
}) => {
    const router = useRouter();
    const { session } = useSession();
    const { alert, showActiveAlert, facets, handleSelectFacet, handleReset } = useNews();
    const { index } = useOrganization();
    const { data } = useQuery<Layout>(LAYOUT);

    useEffect(() => {
        readStorageForSidebar();
    }, []);

    useEffect(() => {
        mobileSidebarVar(false);
    }, [router.pathname]);

    const handleSearchSelect = (suggestion: Search.Suggestion) => {
        switch (suggestion.type) {
            case SUGGESTION_TYPE_NEWS:
                return router.push(
                    { query: { ...router.query, s: NEWS_TYPE_MAIN + ":" + suggestion.slug } },
                    undefined,
                    {
                        shallow: true,
                    }
                );
            case SUGGESTION_TYPE_EMPLOYEE:
                return router.push(getEmployeeQuery(suggestion.id), undefined, { shallow: true });
            case SUGGESTION_TYPE_DEPARTMENT:
                return router.push(getDepartmentQuery(suggestion.id), undefined, { shallow: true });
            default:
                return router.push({ query: { ...router.query, o: suggestion.slug } }, undefined, {
                    shallow: true,
                });
        }
    };

    const openImprint = () => {
        return router.push({ query: { ...router.query, s: "imprint" } }, undefined, {
            shallow: true,
        });
    };

    if (!data) return null;

    return (
        <TemplateWrapper>
            {alert && alert.title && (
                <AlertWrapper>
                    <Alert title={alert.title} onClick={showActiveAlert} />
                </AlertWrapper>
            )}
            <TemplateInner $hasAlert={!!alert && !!alert.title}>
                <SidebarWrapper>
                    <Sidebar
                        active={data.sidebar}
                        path={router.pathname}
                        onToggle={toggleSidebar}
                        openImprint={openImprint}
                        isPreview={router.isPreview}
                    />
                </SidebarWrapper>
                <MobileSidebar active={data.mobileSidebar}>
                    <Sidebar
                        active={data.mobileSidebar}
                        path={router.pathname}
                        onToggle={toggleMobileSidebar}
                        openImprint={openImprint}
                        isPreview={router.isPreview}
                    />
                </MobileSidebar>
                <SidebarOverlay
                    active={data.mobileSidebar}
                    onClick={() => mobileSidebarVar(false)}
                />
                {session && (
                    <NotificationWrapper $active={data.notificationCenter}>
                        <NotificationStage $hasAlert={!!alert && !!alert.title}>
                            <NotificationCenter session={session} />
                        </NotificationStage>
                    </NotificationWrapper>
                )}
                <Overlay active={data.notificationCenter} onClick={toggleNotificationCenter} />
                <TemplateFrame>
                    <Navigation
                        sidebar={data.sidebar}
                        mobileSidebar={data.mobileSidebar}
                        notificationCenter={data.notificationCenter}
                        newsFeed={data.newsFeed}
                        isNews={isNews}
                        isOrga={isOrga}
                        hideGlobalSearch={hideGlobalSearch}
                        facets={facets}
                        onSelectFacet={handleSelectFacet}
                        onResetFacets={handleReset}
                        toggleSidebar={toggleSidebar}
                        toggleMobileSidebar={toggleMobileSidebar}
                        toggleNewsFeed={toggleNewsFeed}
                        onSearchSelect={handleSearchSelect}
                        organization={index}
                        renderNavigationContent={renderNavigationContent}
                        initialSearchTab={activeTabFromPath(router.pathname)}
                        toggleNotificationCenter={() => {
                            if (!session) return;

                            toggleNotificationCenter();
                        }}
                    />
                    {isNews && (
                        <NavigationWrapper>
                            <NewsNavigation
                                facets={facets}
                                state={data.newsType}
                                setState={value => newsTypeVar(value)}
                                onSelectFacet={handleSelectFacet}
                                onReset={handleReset}
                            />
                        </NavigationWrapper>
                    )}
                    <TemplateStageWrapper>
                        <TemplateStage key={router.pathname}>{children}</TemplateStage>
                    </TemplateStageWrapper>
                </TemplateFrame>
            </TemplateInner>
        </TemplateWrapper>
    );
};
