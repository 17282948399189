import { arrToMap } from "../api";
import { getFullName } from "../string";
import { SUGGESTION_TYPE_DEPARTMENT, SUGGESTION_TYPE_EMPLOYEE } from "../search";

export const subordinateEmployees = (
    positions: Organization.Position[],
    employees: Organization.Employee[]
) => {
    const positionsMap = arrToMap(positions, "id") as Record<string, Organization.Position>;

    const results: Record<string, Organization.Position> = {};

    employees.forEach(employee => {
        employee.positions.forEach(position => {
            const positionId = position.positionId;
            const employeesArr = results[positionId] ? results[positionId].employees : undefined;
            results[positionId] = {
                ...positionsMap[positionId],
                employees: employeesArr ? [...employeesArr, employee] : [employee],
            };
        });
    });

    return Object.keys(results).map(resultKey => results[resultKey]);
};

export const subordinatePositions = (
    departments: Organization.Department[],
    positions: Organization.Position[]
): Organization.Department[] => {
    const positionsMap = positions.reduce((map, item) => {
        const department = map[item.organisationId];
        map[item.organisationId] = department ? [...department, item] : [item];
        return map;
    }, {} as Record<string, Organization.Position[]>);

    return departments.map(department => {
        return {
            ...department,
            parent: departments.find(item => item.id === department.parentId) || null,
            positions: positionsMap[department.id] || null,
        };
    });
};

export const employeesFromDepartment = (
    department: Organization.Department,
    departments: Organization.Department[]
): Organization.Employee[] => {
    const dig = (parent: Organization.Department): Organization.Employee[] => {
        const children = departments.filter(child => child.parentId === parent.id);
        return children.reduce((aggr, child) => {
            const employees = employeesFromPositions(child.positions) ?? [];
            return [...aggr, ...employees, ...dig(child)];
        }, [] as Organization.Employee[]);
    };

    const employees = employeesFromPositions(department.positions) ?? [];
    return [...employees, ...dig(department)];
};

export const employeesFromPositions = (positions?: Organization.Position[] | null) => {
    if (!positions) {
        return null;
    }

    const employees = positions.reduce((arr, position) => {
        if (!position.employees) {
            return arr;
        }

        return [...arr, ...position.employees];
    }, [] as Organization.Employee[]);

    return employees.filter((employee, index, self) => {
        return index === self.findIndex(t => t.id === employee.id);
    });
};

export const managerFromPositions = (positions?: Organization.Position[] | null) => {
    if (!positions) {
        return null;
    }

    const position = positions[0];

    if (!position.managementRole || !position.employees) {
        return null;
    }

    return position.employees[0];
};

export const managementFromPositions = (positions?: Organization.Position[] | null) => {
    if (!positions) {
        return null;
    }

    const [management] =
        positions
            ?.filter(position => position.managementRole)
            .flatMap(position => position.employees) ?? [];

    return management ?? null;
};

export const managersFromPositions = (positions?: Organization.Position[] | null) => {
    if (!positions) {
        return null;
    }

    return (positions
        ?.filter(position => position.managementRole)
        .flatMap(position => position.employees) ?? []) as Organization.Employee[];
};

export const positionById = (id: string, positions: Organization.Position[]) => {
    return positions.find(position => position.id === id);
};

export const positionDesignationsFromEmployee = (
    employee: Organization.Employee,
    positions: Organization.Position[]
) => {
    return employee.positions
        .map(position => {
            const pos = positionById(position.positionId, positions);
            return pos ? pos.designation : null;
        })
        .filter(designation => designation !== null);
};

export const hasManagementRoleFromEmployee = (
    employee: Organization.Employee,
    positions: Organization.Position[]
) => {
    const managementRoles = employee.positions.map(position => {
        const pos = positionById(position.positionId, positions);
        return pos ? pos.managementRole : false;
    });

    return managementRoles.includes(true);
};

export const searchSuggestionsFromOrganization = (
    organization: Organization.Index
): Search.Suggestion[] => {
    const employees = organization.employees.map(employee => {
        const suggestion: Search.Suggestion = {
            id: employee.id,
            slug: SUGGESTION_TYPE_EMPLOYEE + ":" + employee.id,
            title: getFullName(employee),
            category: "Employees",
            type: SUGGESTION_TYPE_EMPLOYEE,
        };

        return suggestion;
    });

    const departments = organization.departments.map(department => {
        const suggestion: Search.Suggestion = {
            id: department.id,
            slug: SUGGESTION_TYPE_DEPARTMENT + ":" + department.id,
            title: department.designation,
            category: "Departments",
            description: department.parent ? department.parent.designation : undefined,
            type: SUGGESTION_TYPE_DEPARTMENT,
        };

        return suggestion;
    });

    return [...departments, ...employees];
};

interface Item<T = Organization.Department> {
    item: Organization.Department;
    children: Item<T>[] | null;
}

export const buildOrganizationHierarchy = (departments: Organization.Department[]) => {
    let folded = departments.reduce((fold, item) => {
        const { id, parentId } = item;

        // Set Item
        fold[parentId === null ? "null" : id] = {
            ...fold[id],
            item,
        };

        const target = parentId ?? `null`;

        // Set children
        if (fold[target]?.children) {
            fold[target].children.push(id);
        } else {
            fold[target] = {
                ...fold[target],
                children: [id],
            };
        }

        return fold;
    }, {} as Record<string, { item: Organization.Department; children: string[] }>);

    // Hotswap actual null entry with actual root content
    folded["null"].children = folded[folded["null"]?.children[0]].children;

    const unfold = (key: string, obj: typeof folded): Item => {
        const { item, children } = obj[key];
        return { item, children: children?.map(key => unfold(key, obj)) ?? null };
    };

    return [unfold("null", folded)];
};

export const findDepartmentInHierarchy = (
    hierarchy: ReturnType<typeof buildOrganizationHierarchy>,
    department: Organization.Department
) => {
    const dig = (
        acc: ReturnType<typeof buildOrganizationHierarchy>[number] | null,
        next: ReturnType<typeof buildOrganizationHierarchy>[number]
    ): ReturnType<typeof buildOrganizationHierarchy>[number] | null => {
        if (acc) {
            return acc;
        }

        if (!department || !next) {
            return null;
        }

        if (next.item.id === department.id) {
            return next;
        } else {
            return next.children?.reduce(dig, null) ?? null;
        }
    };

    return hierarchy.reduce(dig, null);
};

export const getEmployees = (
    departments: Organization.Department[],
    department?: Organization.Department | null
): Organization.Employee[] => {
    const f = (
        departments: Organization.Department[],
        department?: Organization.Department | null
    ): Organization.Employee[] => {
        if (!departments || !department) return [];

        const currentDepartmentEmployees =
            department?.positions?.reduce(
                (acc, next) => (next && next.employees ? [...acc, ...next.employees] : acc),
                [] as Organization.Employee[]
            ) ?? [];

        const childrenDepartments = departments.filter(d => d.parentId === department.id);

        return [
            ...currentDepartmentEmployees,
            ...childrenDepartments.flatMap(department => f(departments, department)),
        ];
    };

    return f(departments, department).reduce((acc, next) => {
        if (acc.find(({ id }) => id === next.id)) {
            return acc;
        } else {
            acc.push(next);
            return acc;
        }
    }, [] as Organization.Employee[]);
};

export const countEmployees = (
    departments: Organization.Department[],
    department?: Organization.Department | null
): number => {
    const f = (
        departments: Organization.Department[],
        department?: Organization.Department | null
    ): Organization.Employee[] => {
        if (!departments || !department) return [];

        const currentDepartmentEmployees =
            department?.positions?.reduce(
                (acc, next) => (next && next.employees ? [...acc, ...next.employees] : acc),
                [] as Organization.Employee[]
            ) ?? [];

        const childrenDepartments = departments.filter(d => d.parentId === department.id);

        return [
            ...currentDepartmentEmployees,
            ...childrenDepartments.flatMap(department => f(departments, department)),
        ];
    };

    return Array.from(
        new Set(
            f(departments, department).map(employee =>
                `${employee.academicTitle} ${employee.firstName} ${employee.lastName}`.trim()
            )
        )
    ).length;
};

export const getEmployeesForDepartmentAndChildren = (
    department: Organization.Department | null,
    departments: Organization.Department[]
) => {
    if (!department) {
        return [];
    }

    const f = (
        departments: Organization.Department[],
        department?: Organization.Department | null
    ): Organization.Employee[] => {
        if (!departments || !department) return [];

        const currentDepartmentEmployees =
            department?.positions?.reduce(
                (acc, next) => (next && next.employees ? [...acc, ...next.employees] : acc),
                [] as Organization.Employee[]
            ) ?? [];

        const childrenDepartments = departments.filter(d => d.parentId === department.id);

        return [
            ...currentDepartmentEmployees,
            ...childrenDepartments.flatMap(department => f(departments, department)),
        ];
    };

    return (
        f(departments, department)
            // Remove duplicate employees (e.g holding multiple positions)
            .reduce(
                (acc, next) => (acc.findIndex(a => a.id === next.id) === -1 ? [...acc, next] : acc),
                [] as Organization.Employee[]
            )
    );
};

export const getEmployeesForDepartment = (item?: Organization.Department) => {
    return (
        item?.positions
            // Gather all employees from positions
            ?.reduce(
                (acc, next) =>
                    next?.employees
                        ? // Move managers to top of list
                          next.managementRole
                            ? [...next.employees, ...acc]
                            : [...acc, ...next.employees]
                        : acc,
                [] as Organization.Employee[]
            )
            // Remove duplicate employees (e.g holding multiple positions)
            .reduce(
                (acc, next) => (acc.findIndex(a => a.id === next.id) === -1 ? [...acc, next] : acc),
                [] as Organization.Employee[]
            ) ?? []
    );
};

export const getDepartmentParent = (
    departments: Organization.Department[],
    department?: Organization.Department | null
) => {
    if (!department || !department.parentId) return null;

    const parent = departments.find(parent => parent.id === department.parentId);

    if (parent) {
        return parent;
    }
};

export const getDepartmentPath = (
    departments: Organization.Department[],
    department?: Organization.Department | null
): Organization.Department[] => {
    const parent = getDepartmentParent(departments, department);

    if (parent) {
        return [...getDepartmentPath(departments, parent), parent];
    } else {
        return [];
    }
};

export const removeDuplicates = (employees: Organization.Employee[]): Organization.Employee[] => {
    employees.reduce((acc, next) => {
        if (acc.find(e => e.id === next.id)) {
            return acc;
        } else {
            acc.push(next);
            return acc;
        }
    }, [] as Organization.Employee[]);

    return [];
};
