import { SVGProps } from "react";

export const Hamburger = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.5 11.5" {...props}>
        <g
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5">
            <path data-name="Linie 1403" d="M.75 5.75h15" />
            <path data-name="Linie 1404" d="M.75.75h15" />
            <path data-name="Linie 1405" d="M.75 10.75h15" />
        </g>
    </svg>
);
