import React, { FormEvent } from "react";
import styled from "styled-components";
import { Search } from "../../../icon/Search";
import { useTranslation } from "next-i18next";

const SearchWrapper = styled.form`
    position: relative;
`;

export const SearchInput = styled.input<{ $hideBorder?: boolean }>`
    appearance: none;
    width: 100%;
    height: 3.6rem;
    padding: 0 0 0 3rem;
    border: 0.1rem solid ${p => p.theme.gray300};
    border: ${p => p.$hideBorder && "none"};
    background-color: ${p => p.theme.gray50};
    color: inherit;
    line-height: 1;
    font-size: 1.6rem;
    transition: border-color 0.2s;
    will-change: border-color;

    &::placeholder {
        color: ${p => p.theme.gray700};
    }

    @media (hover: hover) {
        &:hover {
            border-color: ${p => p.theme.gray600};
        }
    }

    &:focus,
    &:not(:placeholder-shown) {
        border-color: ${p => p.theme.gray600};
    }
`;

const IconWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 100%;
    color: ${p => p.theme.gray700};
`;

const SearchIcon = styled(Search)`
    width: 1.5rem;
    height: 1.5rem;
`;

export interface SearchBarProps {
    value: string;
    onInput: (value: string) => void;
    onSubmit?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
    placeholder?: string;
    hideBorder?: boolean;
}

export const SearchBar: React.FC<SearchBarProps> = ({
    value,
    onInput,
    onFocus,
    onBlur,
    onSubmit,
    placeholder,
    hideBorder,
}) => {
    const { t } = useTranslation();

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (!onSubmit) {
            return;
        }

        onSubmit();
    };

    return (
        <SearchWrapper autoComplete="off" onSubmit={handleSubmit}>
            <IconWrapper>
                <SearchIcon />
            </IconWrapper>
            <SearchInput
                name="search"
                placeholder={placeholder || t("search")}
                value={value}
                onFocus={onFocus}
                onBlur={onBlur}
                onInput={e => onInput((e.target as HTMLInputElement).value)}
                $hideBorder={hideBorder}
            />
        </SearchWrapper>
    );
};
