import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import { SearchBar } from "../../atom/search-bar/SearchBar";
import { filterNewsByStr } from "../../../lib/api/data/contentful/news";
import { Suggestions } from "../suggestions/Suggestions";
import { useRouter } from "next/router";
import { MobileSearch } from "../../molecule/mobile-search/MobileSearch";
import { Search as SearchIcon } from "../../../icon/Search";
import { filterOrganizationByStr } from "../../../lib/api/organization";
import { searchSuggestionsFromOrganization } from "../../../lib/organization";
import { searchSuggestionsFromNews } from "../../../lib/news";
import { flattenArray } from "../../../lib/api";
import { useClickOutside } from "../../../lib/click";
import { defaultLocale } from "../../../lib/language";

const SearchWrapper = styled.div`
    position: relative;
`;

const SearchBarWrapper = styled.div`
    display: none;
    width: 20rem;

    @media ${p => p.theme.bp.m} {
        display: block;
    }

    @media ${p => p.theme.bp.l} {
        width: 27rem;
    }
`;

const SuggestionsWrapper = styled.div`
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    min-width: 40rem;
    max-width: 60rem;
    max-height: 75vh;
    transform: translateY(calc(100% + 0.5rem));
    overflow: hidden;
    white-space: nowrap;
    box-shadow: ${p => p.theme.cardShadow};

    @media ${p => p.theme.bp.m} {
        display: flex;
        flex-direction: column;
    }
`;

const MobileSearchWrapper = styled.div`
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media ${p => p.theme.bp.m} {
        display: none;
    }
`;

const MobileSearchButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;

    @media ${p => p.theme.bp.m} {
        display: none;
    }
`;

const IconSearch = styled(SearchIcon)`
    width: 1.8rem;
    height: 1.8rem;
`;

export interface GlobalSearchProps {
    organization: Organization.Index;
    onSelect: (suggestion: Search.Suggestion) => void;
    initialTab?: Search.Tab;
}

export const GlobalSearch: React.FC<GlobalSearchProps> = ({
    organization,
    onSelect,
    initialTab,
}) => {
    const router = useRouter();
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const [active, setActive] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [suggestions, setSuggestions] = useState<Search.Suggestion[]>([]);

    useClickOutside(wrapperRef, () => setActive(false));

    const handleQuery = (value: string) => {
        if (!value) {
            setSuggestions([]);
            return;
        }

        Promise.all([getNewsSuggestions(value), getOrganizationSuggestions(value)]).then(res =>
            setSuggestions(flattenArray(res))
        );
    };

    const debouncedFunc = useCallback(debounce(handleQuery, 100), []);

    const getNewsSuggestions = (value: string) => {
        return filterNewsByStr(value, router.isPreview, router.locale ?? defaultLocale).then(
            news => {
                if (!news) {
                    return [];
                }

                return searchSuggestionsFromNews(news);
            }
        );
    };

    const getOrganizationSuggestions = (value: string) => {
        return searchSuggestionsFromOrganization(filterOrganizationByStr(value, organization));
    };

    const handleSelect = (suggestion: Search.Suggestion) => {
        setActive(false);
        onSelect(suggestion);
    };

    return (
        <SearchWrapper ref={wrapperRef}>
            {active && (
                <MobileSearchWrapper>
                    <MobileSearch
                        value={search}
                        suggestions={suggestions}
                        onSelect={handleSelect}
                        initialTab={initialTab}
                        onClose={() => setActive(false)}
                        onInput={value => {
                            setSearch(value);
                            debouncedFunc(value);
                        }}
                    />
                </MobileSearchWrapper>
            )}
            <MobileSearchButton type="button" onClick={() => setActive(true)}>
                <IconSearch />
            </MobileSearchButton>
            <SearchBarWrapper>
                <SearchBar
                    value={search}
                    onFocus={() => setActive(true)}
                    onInput={value => {
                        setSearch(value);
                        debouncedFunc(value);
                    }}
                />
            </SearchBarWrapper>
            {active && search && suggestions && (
                <SuggestionsWrapper>
                    <Suggestions
                        searchStr={search}
                        initialTab={initialTab}
                        suggestions={suggestions}
                        onClick={handleSelect}
                    />
                </SuggestionsWrapper>
            )}
        </SearchWrapper>
    );
};
