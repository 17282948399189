import React from "react";
import styled from "styled-components";
import { SwitchTheme } from "../../atom/switch-theme/SwitchTheme";
import { HeadingS } from "../../../css/typography";
import { UserState } from "../../atom/user-state/UserState";
import { SignOut } from "../../../icon/SignOut";
import { Avatar } from "../../atom/avatar/Avatar";
import { LanguageDropdown } from "../language-dropdown/LanguageDropdown";
import { useRouter } from "next/router";
import { useCustomTheme } from "../../../lib/context/theme/CustomThemeProvider";
import { defaultLocale, languages } from "../../../lib/language";
import { useTranslation } from "next-i18next";

const WidgetWrapper = styled.div`
    width: 100%;
    background-color: ${p => p.theme.gray50};
    border-radius: 0.8rem;
`;

const WidgetHead = styled.div`
    display: flex;
`;

const WidgetGroup = styled.div`
    padding: 1.4rem 2rem;
    border-bottom: 0.1rem solid ${p => p.theme.gray100};

    &:first-child {
        padding-top: 2rem;
    }

    &:last-child {
        border: none;
    }
`;

const WidgetRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.4rem;

    &:last-child {
        margin: 0;
    }
`;

const WidgetInfo = styled.div`
    margin-left: 1rem;
`;

const WidgetGroupFrame = styled.div`
    margin-right: 1rem;
`;

const FlexWrapper = styled.div`
    flex: 1;
    max-width: 20rem;

    @media ${p => p.theme.bp.m} {
        max-width: 70%;
    }
`;

export const WidgetName = styled(HeadingS)`
    margin-bottom: 0.2rem;
`;

export const ButtonSignOut = styled.button`
    display: flex;
    align-items: center;
    line-height: 1;
`;

const IconSignOut = styled(SignOut)`
    width: 1.7rem;
    height: 2rem;
    margin-right: 0.8rem;
`;

export interface WidgetUserProps {
    name: string;
    active: boolean;
    onSignOut?: () => void;
}

export const WidgetUser: React.FC<WidgetUserProps> = ({ name, active, onSignOut }) => {
    const router = useRouter();
    const activeLocale = router ? router.locale ?? defaultLocale : defaultLocale;
    const { t } = useTranslation();
    const { theme, toggleTheme } = useCustomTheme();

    const handleLocale = async (locale: string) => {
        location.href = `/${locale}${router.pathname}`;
    };

    return (
        <WidgetWrapper>
            <WidgetGroup>
                <WidgetHead>
                    <Avatar name={name} isLarge />
                    <WidgetInfo>
                        <WidgetName>{name}</WidgetName>
                        <UserState active={active} />
                    </WidgetInfo>
                </WidgetHead>
            </WidgetGroup>
            <WidgetGroup>
                <WidgetRow>
                    <WidgetGroupFrame>{t("theme")}</WidgetGroupFrame>
                    <FlexWrapper>
                        <SwitchTheme theme={theme} onToggle={toggleTheme} />
                    </FlexWrapper>
                </WidgetRow>
                <WidgetRow>
                    <WidgetGroupFrame>{t("language")}</WidgetGroupFrame>
                    <FlexWrapper>
                        <LanguageDropdown
                            languages={languages}
                            activeLocale={activeLocale}
                            onLocaleChange={handleLocale}
                        />
                    </FlexWrapper>
                </WidgetRow>
            </WidgetGroup>
            {/*<WidgetGroup>*/}
            {/*    <ButtonSignOut type="button" onClick={onSignOut}>*/}
            {/*        <IconSignOut />*/}
            {/*        {t("signOut")}*/}
            {/*    </ButtonSignOut>*/}
            {/*</WidgetGroup>*/}
        </WidgetWrapper>
    );
};
