import React from "react";
import styled from "styled-components";
import { Dropdown } from "../../atom/dropdown/Dropdown";
import { useOrganization } from "../../../lib/context/organization/OrganizationContext";
import { Button } from "../../atom/button/Button";
import { ReturnArrow } from "../../../icon/ReturnArrow";
import { useTranslation } from "react-i18next";

const FilterOrgaWrapper = styled.div`
    display: flex;
    gap: 1rem;
`;

const ButtonContent = styled.div`
    display: flex;
    align-items: center;
`;

const ReturnIconWrapper = styled.div`
    margin-right: 0.5rem;
`;

export const FilterOrga: React.FC = () => {
    const { setIsChart, isChart, department, employee, setDepartmentById } = useOrganization();
    const { t } = useTranslation();

    const onSelect = (key: string) => {
        setIsChart(key === "Chart");
    };

    const onBack = () => {
        setDepartmentById(employee ? department?.id : department?.parentId);
    };

    return (
        <FilterOrgaWrapper>
            <Dropdown
                title="Mode"
                value={isChart ? "Chart" : "Liste"}
                options={{ Chart: "chart", Liste: "list" }}
                onSelect={onSelect}
            />
            <Button action={onBack}>
                <ButtonContent>
                    <ReturnIconWrapper>
                        <ReturnArrow height="1.2rem" />
                    </ReturnIconWrapper>
                    {t("back")}
                </ButtonContent>
            </Button>
        </FilterOrgaWrapper>
    );
};
